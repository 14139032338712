import React from 'react';
import {
	List, Datagrid, SimpleForm, TopToolbar,
	Edit, Create, Filter,
	TextField, EmailField, BooleanField,
	TextInput, BooleanInput,
	required, email,
	ShowButton,
	usePermissions, useTranslate
} from 'react-admin';
import { Title, QueryableTextInput } from '../common';
import { ListActions } from './utils/pdfDownload';

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();
	return <TopToolbar> <ShowButton record={data} label={translate('showHistory')} /> </TopToolbar>;
}

const UserFilter = props => (
	<Filter {...props}>
		<QueryableTextInput source="firstName" />
		<QueryableTextInput source="lastName" />
		<QueryableTextInput source="emailAddress" />
		<BooleanInput source="isManager" />
	</Filter>
);

export const UserList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;
	return (
		<List filters={<UserFilter />} {...props}
			actions={<ListActions {...props} />}
			title="Escalation-Manager">
			<Datagrid rowClick="edit">
				<TextField source="firstName" />
				<TextField source="lastName" />
				<EmailField source="emailAddress" />
				<BooleanField source="isManager" />
			</Datagrid>
		</List>
	)
};

const name = x => x.firstName && x.lastName && `${x.firstName} ${x.lastName}`

export const UserEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return <Edit actions={<PostEditActions />} title={<Title prefix={translate('edit')} resource="User" select={name} />} {...props}>
		<SimpleForm>
			<TextInput source="firstName" validate={required()} />
			<TextInput source="lastName" validate={required()} />
			<TextInput source="emailAddress" type="email" validate={[required(), email()]} />
			<BooleanInput source="isManager" />
		</SimpleForm>
	</Edit>
};

export const UserCreate = props => {
	const translate = useTranslate();
	return <Create redirect="list" title={<Title prefix={translate('create')} resource="User" select={name} />} {...props}>
		<SimpleForm >
			<TextInput source="firstName" validate={required()} />
			<TextInput source="lastName" validate={required()} />
			<TextInput source="emailAddress" type="email" validate={[required(), email()]} />
			<BooleanInput source="isManager" defaultValue={false} />
		</SimpleForm>
	</Create>
};
