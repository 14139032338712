import React from 'react';
import {
	Create, Edit, Filter,
	List, Datagrid, SimpleForm, TopToolbar,
	DateField, ReferenceField, TextField, FunctionField,
	DateTimeInput, ReferenceInput, AutocompleteInput,
	required,
	ShowButton,
	useRecordContext, usePermissions, useTranslate,
	FormDataConsumer
} from 'react-admin';
import { Title, } from '../common';
import { query } from "../../common/functions";
import { ListActions } from './utils/pdfDownload';
import * as moment from 'moment';

const divisionQuery = query("name");
// use the email address because it includes the first & last names
const managerQuery = query("emailAddress");

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();

	return <TopToolbar> <ShowButton
		record={data}
		label={translate('showHistory')} /> </TopToolbar>;
}

const DutyFilter = props => {
	const record = useRecordContext();
	return (
		<Filter {...props}>
			<ReferenceInput source="managerId"
				reference="users"
				filter={{ isManager: true }}>
				<AutocompleteInput optionText={(choice) => `${choice.firstName} ${choice.lastName}`}
					filterToQuery={managerQuery}
				/>
			</ReferenceInput>
			<DateTimeInput source="from" />
			<DateTimeInput source="to" />
			<ReferenceInput source="divisionId"
				reference="divisions"
			>
				<AutocompleteInput optionText="name"
					filterToQuery={divisionQuery}
				/>
			</ReferenceInput>
		</Filter>
	);
}

export const DutyList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List filters={<DutyFilter />}
			actions={<ListActions {...props} />}
			{...props}>
			<Datagrid rowClick="edit">
				<ReferenceField source="managerId"
					reference="users">
					<FunctionField render={record => `${record.firstName} ${record.lastName}`} />
				</ReferenceField>
				<DateField source="from"
					showTime />
				<DateField source="to"
					showTime />
				<ReferenceField source="divisionId"
					reference="divisions">
					<TextField source="name" />
				</ReferenceField>
			</Datagrid>
		</List>
	)
};

const dateValidator = (date) => {
	if (date && typeof date !== 'string') return date.toISOString().split('.')[0];
}

const DutyForm = props => (
	<SimpleForm {...props}>
		<ReferenceInput source="managerId"
			reference="users"
			filter={{ isManager: true }}>
			<AutocompleteInput
				validate={required()}
				optionText={(choice) => choice ? `${choice.firstName} ${choice.lastName}` : "/"}
				filterToQuery={managerQuery}
				isOptionEqualToValue={(option, value) => option._id === value._id}
			/>
		</ReferenceInput>
		<FormDataConsumer>
			{({ formData, ...rest }) =>
				<DateTimeInput
					source="from"
					validate={required()}
					inputProps={{
						max: dateValidator(formData.to)
					}}
					{...rest}
				/>
			}
		</FormDataConsumer>
		<FormDataConsumer>
			{({ formData, ...rest }) =>
				<DateTimeInput
					source="to"
					validate={required()}
					inputProps={{
						min: dateValidator(formData.from)
					}}
					{...rest}
				/>
			}
		</FormDataConsumer>
		<ReferenceInput source="divisionId"
			reference="divisions"
		>
			<AutocompleteInput
				validate={required()}
				optionText="name"
				filterToQuery={divisionQuery}
			/>
		</ReferenceInput>
	</SimpleForm>
)

const duty = _ => 'Duty'

export const DutyEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (<Edit actions={<PostEditActions />} title={<Title prefix={translate('edit')} resource="Duty" select={duty} />} {...props} mutationMode="pessimistic">
		<DutyForm />
	</Edit>
	)
};

export const DutyCreate = props => {
	const translate = useTranslate();

	return (<Create redirect="list" title={<Title prefix={translate('create')} resource="Duty" select={duty} />} {...props}>
		<DutyForm />
	</Create>
	)
};
