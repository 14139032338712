import React from 'react';
import {
	Create, Edit,
	List, Datagrid, Filter, SimpleForm, TopToolbar,
	required,
	ShowButton,
	TextField,
	TextInput,
	usePermissions, useTranslate
} from 'react-admin';
import { QueryableTextInput, Title } from '../common'
import { ListActions } from './utils/pdfDownload';

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();
	return <TopToolbar> <ShowButton record={data} label={translate('showHistory')} /> </TopToolbar>;
}

export const CountryFilter = props => {
	const translate = useTranslate();

	return <Filter {...props}>
		<QueryableTextInput source="nameEn" label={translate('resources.countries.filter.nameEn')} />
		<QueryableTextInput source="nameDe" label={translate('resources.countries.filter.nameDe')} />
		<QueryableTextInput source="abbreviationEn" label={translate('resources.countries.filter.abbreviationEn')} />
		<QueryableTextInput source="abbreviationDe" label={translate('resources.countries.filter.abbreviationDe')} />
	</Filter>
};

export const CountryList = () => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List perPage={25}
			filters={<CountryFilter />}
			actions={<ListActions />}
		>
			<Datagrid rowClick="edit">
				<TextField source="nameEn" />
				<TextField source="nameDe" />
				<TextField source="abbreviationEn" />
				<TextField source="abbreviationDe" />
			</Datagrid>
		</List>
	)
};

const name = x => x.nameEn

export const CountryEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return <Edit actions={<PostEditActions />}
		title={<Title prefix={translate('edit')}
			resource="Country"
			select={name} />} {...props}>
		<CreateEditFormCountry />
	</Edit>
};

export const CountryCreate = props => {
	const translate = useTranslate();

	return <Create redirect="list" title={<Title prefix={translate('create')}
		resource="Country"
		select={name} />} {...props}>
		<CreateEditFormCountry />
	</Create>
};

export const CreateEditFormCountry = props => (
	<SimpleForm {...props}>
		<TextInput source="nameEn"
			validate={required()} />
		<TextInput source="nameDe"
			validate={required()} />
		<TextInput source="abbreviationEn" />
		<TextInput source="abbreviationDe" />
	</SimpleForm>
)
