const localEnglishMessages = {
	languages: {
		de: "German",
		bg: "Bulgarian",
		cs: "Czech",
		da: "Danish",
		de_AT: "German-Austria",
		de_CH: "German-Switzerland",
		de_LU: "German-Luxemburg",
		el: "Greek",
		el_CY: "Greek-Cyprus",
		en: "English",
		es: "Spanish",
		et: "Estonian",
		fi: "Finish",
		fr: "French",
		fr_BE: "French-Belgium",
		fr_CH: "French-Switzerland",
		fr_LU: "French-Luxemburg",
		hr: "Croatian",
		hu: "Hungarian",
		it: "Italian",
		it_CH: "Italian-Switzerland",
		lt: "Lithuanian",
		lv: "Latvian",
		mt: "Maltese",
		nl: "Dutch",
		nl_BE: "Dutch-Belgium",
		pl: "Polish",
		pt: "Portuguese",
		ro: "Romanian",
		sk: "Slovak",
		sl: "Slovenian",
		sr: "Serbian",
		sv: "Swedish",
		tr: "Turkish",
	},
	login: {
		signInTitle: "Sign in via SSO",
		signInBtn: "Sign in",
		errorRights: "You don't have the required Role. Please request it",
		errorLogin: "Login failed. Please contact your administrator",
	},
	save: "Save",
	create: "Create",
	edit: "Edit",
	finish: "Finish",
	next: "Next",
	showHistory: "Show History",
	escalationCreatedSuccess: "Escalation succesfully created. Automatic translations of the description and impact will be availa in about 2 minutes.",
	escalationCreatedError: "Error creating escalation. %{error}",
	escalationApproveError: "Error approving escalation. Active declines for this escalation: %{declines}",
	escalationApprove: "Escalation approved",
	unauthorized: "You are not authorized to perform this action",
	approveButton: "Approve review",
	declineButton: "Decline review",
	resources: {
		menu: {
			general_title: "General",
			countries: "Countries",
			divisions: "Divisions",
			businessImpactManagers: "Business Impact Managers",

			escalations_title: "Escalations",
			categories: "Categories",
			escalations: "Escalations",
			escalationDrafts: 'Escalation Drafts',
			hiddenEscalations: "Hidden Entry",

			serviceteams_title: "ServiceTeams",
			serviceTeams: "Service Teams",
			teamCategories: "Team Categories",

			duties_title: "Duties",
			users: "Escalation-Manager",
			duties: "Duties",

			history_title: "History",
			changeHistory: "Change History",
			reviewDashboard: "Review Dashboard",

			declines: "Declines"
		},
		countries: {
			name: "Countries",
			fields: {
				nameEn: "Name En",
				nameDe: "Name De",
				abbreviationEn: "Abbreviation En",
				abbreviationDe: "Abbreviation De",
			},
			filter: {
				nameEn: "Name EN",
				nameDe: "Name DE",
				abbreviationEn: "Abbreviation En",
				abbreviationDe: "Abbreviation De",
			},
		},
		divisions: {
			name: "Divisions",
			fields: {
				name: "Name",
				abbreviation: "Abbreviation",
			},
			filter: {
				name: "Name",
				abbreviation: "Abbreviation",
			},
		},
		businessImpactManagers: {
			name: "Business Impact Managers",
			general: "General",
			phone: "Phone",
			fields: {
				description: "Description",
				countryId: "Country",
				phoneNumber: "Phone Number",
				phoneNumberType: "Phone Number Type",
				divisionId: "Division",
			},
			filter: {
				description: "Description",
				phoneNumber: "Phone Number",
				phoneNumberType: "Phone Number Type",
			},
		},
		categories: {
			name: "Categories",
			fields: {
				nameEn: "Name En",
				nameDe: "Name De",
			},
			filter: {
				nameEn: "Name En",
				nameDe: "Name De",
			},
		},
		escalations: {
			solution: "Select the responsible Solution or Application",
			name: "Escalations",
			draftNeedsSolutionApproval: "This draft still needs an approval from a member of the solution team.",
			draftNeedsCCApproval: "This draft still needs an approval from a control center admin.",
			draftNeedsBothApproval: "This draft still needs an approval from a member of the solution team and a control center admin.",
			draftNeedsEitherApproval: "This draft still needs an approval from a member of the solution team or a control center admin.",
			countrySelection: "Countries selection",
			divisionsSelection: "Divisions selection",
			processSystemApplication:
				"Select a matching process / system / application for the escalation",
			isDraft: "Draft",
			majorTrigger: "Major-Trigger",
			englishMajorTrigger: "Major-Trigger in English",
			descriptionShort: "Case Description",
			description: "Description of the escalation",
			englishDescriptionEscalation: "English description of the escalation",
			affectedCi: "Affected CI",
			impact: "Description of the impact",
			impactEn: 'English description for the impact',
			businessCriticalTime: "Business critical hours",
			businessCriticalDays: "Business critical days",
			primaryContactId: "Primary contact (if you cannot find your service team, please contact control center)",
			secondaryContactsIds: "Secondary contacts",
			language: "Language to display dynamic, user-generated content in (description, impact)",
			businessImpactManagersIds: "Business Impact Managers",
			responsiblePerson: "Responsible Person",
			responsibleGroup: "Responsible Group",
			mailSubjectDe: "Subject in german",
			mailSubjectEn: "Subject in english",
			mailTextDe: "Mail text in german",
			mailTextEn: "Mail text in english",
			smsTextDe: "SMS text in german",
			smsTextEn: "SMS text in english",
			linkResources: 'Link Resource',
			confirmMsg:
				"All steps completed",
			steps: {
				countryDivisions: "Countries & Divisions",
				processSystemApplication: "Process, System, Application",
				description: "Case Description",
				impact: "Business Impact",
				businessCriticalTime: "Business critical time",
				contacts: "Contacts",
				linkResources: 'Link Resources (optional)',
				mailTemplate: "Mail template (optional)",
				sMSTemplate: "SMS template (optional)",
			},
			fields: {
				countryId: "Country",
				categoryId: "Category",
				solution: "Solution",
				solutionDisplayId: "Solution Display ID",
				description: "Description",
				impact: "Impact",
				primaryContactId: "Primary Contact",
				serviceTeams: "Service Teams",
				businessImpactManagersIds: "Business Impact Managers",
				divisions: "Divisions",
				divisionsIds: 'Divisions',
				_id: "Id",
				lastUpdater: "Last Updated By",
				lastUpdatedAt: "Last Update At",
				draftApprover: "Draft Approver",
				draftApprovedAt: "Draft Approved Date",
				reviewApprover: "Review Approver",
				reviewApprovedAt: "Review Approved Date",
				furtherContacts: "Further Contacts"
			},
			filter: {
				countryId: "Country",
				categoryId: "Category",
				majorTrigger: "Major Trigger EN",
				description: "Description EN",
				impact: "Impact  EN",
				solution: "Solution",
				primaryContactId: "Primary Contact",
				secondaryContactsIds: "Secondary Contacts",
				serviceTeams: "Service Teams",
				businessImpactManagersIds: "Business Impact Managers",
				divisions: "Divisions",
			},
		},
		escalationDrafts: {
			name: 'Escalation Drafts',
			fields: {
				countryId: "Country",
				categoryId: "Category",
				solution: "Solution",
				solutionDisplayId: "Solution Display ID",
				description: "Description",
				impact: "Impact",
				primaryContactId: "Primary Contact",
				serviceTeams: "Service Teams",
				businessImpactManagersIds: "Business Impact Managers",
				divisions: "Divisions",
				divisionsIds: 'Divisions',
				_id: "Id",
				lastUpdater: "Last Updated By",
				lastUpdatedAt: "Last Update At",
				draftApprover: "Draft Approver",
				draftApprovedAt: "Draft Approved Date",
				reviewApprover: "Review Approver",
				reviewApprovedAt: "Review Approved Date",
				furtherContacts: "Further Contacts"
			},
		},
		serviceTeams: {
			name: "Service Teams",
			fields: {
				it4youId: "IT4YOU ID",
				it4youTeam: "IT4YOU Team",
				categoryId: "Category",
				availabilities: "Availabilities",
				remarkEn: "Remark En",
				remarkDe: "Remark De",
				phoneNumber: "Phone Number",
				phoneNumberType: "Type",
				nationalHolidays: "Including national holidays",
			},
			filter: {
				it4youId: "It4you Id",
				it4youTeam: "It4you Team",
				remarkEn: "Remark En",
				remarkDe: "Remark De",
				categoryId: "Category",
				availabilities: "Availabilities",
			},
			form: {
				tabGeneral: "General",
				tabAvailabilities: "Availabilities",
				clockTime: "Clock time",
				fromTime: "From (Time)",
				toTime: "To (Time)",
				dateTime: "Date time",
				fromDate: "From (Date)",
				toDate: "To (Date)",
				phone: "Phone",
			},
		},
		teamCategories: {
			name: "Team Categories",
			fields: {
				name: "Name",
				timeZone: "Time Zone",
			},
			filter: {
				name: "Name",
				timeZone: "Time Zone",
			},
		},
		users: {
			name: "Escalation-Manager",
			fields: {
				firstName: "First Name",
				lastName: "Last Name",
				emailAddress: "Email address",
				isManager: "Escalation-Manager",
			},
			filter: {
				firstName: "First Name",
				lastName: "Last Name",
				emailAddress: "Email address",
				isManager: "Escalation-Manager",
			},
		},
		duties: {
			name: "Duties",
			fields: {
				managerId: "Manager",
				from: "From",
				to: "To",
				divisionId: "Division",
			},
			filter: {
				managerId: "Manager",
				from: "From",
				to: "To",
				divisionId: "Division",
			},
		},
		changeHistory: {
			name: "Change History",
			fields: {
				entityType: "Table",
				docId: "ID",
				changedFields: "Fields",
				method: "Process",
				user: "Person",
				createdAt: "Date, Time",
			},
			filter: {
				entityType: "Table",
				docId: "ID",
				changedFields: "Fields",
				method: "Process",
				user: "Person",
				createdAt: "Date, Time",
			},
		},
	},
	emailSettings: {
		heading: "Email Preferences",
		explanation: "For different events, the escalation tool will notify you via email. You can deselect individual events to not trigger an email notification for you:",
		escaApproveCreateByCCAdminToApprover: "you approved the creation of an escalation (as a control center admin)",
		escaApproveCreateByCCAdminToCCAdmins: "another control center admin approved the creation of an escalation",
		escaApproveCreateByCCAdminToCreator: "a control center admin approved the creation of your escalation",
		escaApproveCreateByCCAdminToSolutionAdmins: "a control center admin approved the creation of an escalation for your solution",
		escaApproveCreateBySolutionAdminToSolutionAdmins: "another solution admin from your solution team approved the creation of an escalation for your common solution",
		escaApproveCreateBySolutionAdminToCreator: "the creation of your escalation has been approved by a solution admin",
		escaApproveCreateBySolutionAdminToCCAdmins: "the creation of an escalation has been approved by a solution admin (for control center admins)",
		escaApproveCreateBySolutionAdminToApprover: "you approved the creation of an escalation (as a solution admin)",
		escaApproveModificationByCCAdminToSolutionAdmins: "a control center admin approved a modification to an escalation for your solution",
		escaApproveModificationByCCAdminToEditor: "a control center admin approved your modification to an escalation",
		escaApproveModificationByCCAdminToCreator: "a control center admin approved a modification to your escalation",
		escaApproveModificationByCCAdminToCCAdmins: "another control center admin approved a modification to an escalation",
		escaApproveModificationByCCAdminToApprover: "you approved a modification of an escalation (as a control center admin)",
		escaApproveModificationBySolutionAdminToEditor: "a solution admin approved your modification to an escalation",
		escaApproveModificationBySolutionAdminToCreator: "a solution admin approved a modification to your escalation",
		escaApproveModificationBySolutionAdminToCCAdmins: "a solution admin approved a modification to an escalation (for control center admins)",
		escaApproveModificationBySolutionAdminToApprover: "you approved a modification of an escalation for your solution",
		escaApproveModificationBySolutionAdminToSolutionAdmins: "another solution admin approved a modification to an escalation for your common solution",
		escaCreatedBySolutionAdminToCreator: "you created an escalation for your solution (as a solution admin)",
		escaCreatedBySolutionAdminToCCAdmins: "a solution admin created an escalation",
		escaCreatedByCCAdminToSolutionAdmins: "a control center admin created an escalation for your solution",
		escaCreatedByCCAdminToCreator: "you created an escalation (as a control center admin)",
		escaCreatedByCCAdminToCCAdmins: "another control center admin created an escalation",
		escaCreatedBySolutionAdminToSolutionAdmins: "another solution admin created an escalation for your common solution",
		draftCreatedByUserToSolutionAdmins: "a user created an escalation draft for your solution",
		draftCreatedByUserToCreator: "you created an escalation draft",
		draftCreatedByUserToCCAdmins: "a user created an escalation draft (for control center admins)",
		escaDeclineCreateByCCAdminToSolutionAdmins: "the creation of an escalation for your solution has been declined by a control center admin",
		escaDeclineCreateByCCAdminToCreator: "the creation of your escalation has been declined by a control center admin",
		escaDeclineCreateByCCAdminToCCAdmins: "the creation of an escalation has been declined by another control center admin",
		escaDeclineCreateByCCAdminToApprover: "you declined the creation of an escalation (as a control center admin)",
		escaDeclineCreateBySolutionAdminToSolutionAdmins: "the creation of an escalation has been declined by another solution admin",
		escaDeclineCreateBySolutionAdminToCreator: "the creation of your escalation has been declined by a solution admin",
		escaDeclineCreateBySolutionAdminToCCAdmins: "the creation of an escalation has been declined by a solution admin",
		escaDeclineCreateBySolutionAdminToApprover: "you declined the creation of an escalation (as a solution admin)",
		escaDeclineModificationByCCAdminToSolutionAdmins: "a modification of an escalation for your solution has been declined by a control center admin",
		escaDeclineModificationByCCAdminToEditor: "your modification of an escalation has been declined by a control center admin",
		escaDeclineModificationByCCAdminToCreator: "a modification of your escalation has been declined by a control center admin",
		escaDeclineModificationByCCAdminToCCAdmins: "a modification of an escalation has been declined by another control center admin",
		escaDeclineModificationByCCAdminToApprover: "you declined a modification of an escalation (as a control center admin)",
		escaDeclineModificationBySolutionAdminToSolutionAdmins: "a modification of an escalation has been declined by another solution admin",
		escaDeclineModificationBySolutionAdminToEditor: "your modification of an escalation has been declined by a solution admin",
		escaDeclineModificationBySolutionAdminToCreator: "a modification of your escalation has been declined by a solution admin",
		escaDeclineModificationBySolutionAdminToCCAdmins: "a modification of an escalation has been declined by a solution admin (for control center admins)",
		escaDeclineModificationBySolutionAdminToApprover: "you declined a modification of an escalation (as a solution admin)",
		escaModifiedByCCAdminToSolutionAdmins: "an escalation for your solution has been modified by a control center admin",
		escaModifiedByCCAdminToEditor: "you modified a solution (as a control center admin)",
		escaModifiedByCCAdminToCreator: "your escalation has been modified by a control center admin",
		escaModifiedByCCAdminToCCAdmins: "an escalation has been modified by another control center admin (for control center admins)",
		escaModifiedBySolutionAdminToSolutionAdmins: "an escalation for your solution has been modified by another solution admin",
		escaModifiedBySolutionAdminToEditor: "you modified a solution (as a solution admin)",
		escaModifiedBySolutionAdminToCreator: "your escalation has been modified by a solution admin",
		escaModifiedBySolutionAdminToCCAdmins: "an escalation has been modified by a solution admin (for control center admins)",
		escaModifyRequestByUserToSolutionAdmins: "a user requested a modification of an escalation for your solution",
		escaModifyRequestByUserToEditors: "you requested a modification of an escalation",
		escaModifyRequestByUserToCCAdmins: "a user requested a modification of an escalation (for control center admins)",
		save: "Save"
	},
	reviewDashboard: {
		pageTitle: "Review Dashboard",
		accordionFirstTabTitle: "View Escalations out of Grace Period and hidden",
		accordionSecondTabTitle: "View Escalations needing a review and in Grace Period",
		accordionThirdTabTitle: "View Reviewed Escalations",
		dashboardChartGreenColumnText: "Reviewed",
		dashboardChartYellowColumnText: "In review / Grace period",
		dashboardChartRedColumnText: "Out of grace period / Not reviewed and hidden",
	}
};

export default localEnglishMessages;
