import React, { useState } from 'react';
import {
	List, Datagrid, SimpleForm, SingleFieldList, Toolbar, TopToolbar, SimpleFormIterator,
	Edit, Filter,
	AutocompleteArrayInput, AutocompleteInput, SelectInput, ReferenceArrayInput, ReferenceInput, TextInput, ArrayInput,
	ChipField, ReferenceArrayField, ReferenceField, TextField,
	required,
	SaveButton, ShowButton,
	useDelete, useGetOne, useRecordContext, useRedirect, useTranslate, useUpdate, useNotify, usePermissions, useLocale,
} from 'react-admin';
import { Title } from '../common';
import { query, prependHttps } from "../../common/functions";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import Paper from '@mui/material/Paper';
import Popup from "./popup";
import { CreateEditFormCountry } from "./country";
import { CreateEditFormDivision } from "./division"
import { CreateEditFormCategory } from "./category";
import { ServiceTeamForm } from './serviceTeam';
import { BusinessImpactManagerForm } from './businessImpactManager';
import { useFormContext, useWatch } from 'react-hook-form';
import { languageCodes } from "./translations/languageCodes";
import { ListActions } from './utils/pdfDownload';
import config from '../../common/config';


const useStyles = makeStyles((theme) => ({
	inlineBlock: { display: 'inline-flex', marginRight: '2rem' },
	firstTextMargin: { marginBottom: '-1rem', marginTop: '0.8rem' },
	textMargin: { marginBottom: '-1rem', marginTop: '-0.8rem' },
	root: {
		width: '90%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	completed: {
		display: 'inline-block',
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	resetButton: {
		color: theme.palette.info.light
	}
}));

const categoryQuery = query("nameEn");
const solutionQuery = query("name");
const divisionQuery = query("name");
const countryQuery = query("nameEn");
const nameEnQuery = query("nameEn");
const serviceTeamQuery = query("it4youTeam");
const businessImpactManagerQuery = query("description");

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(x => ({
	id: x,
	name: x
}));
const escalation = _ => 'Escalation';

// ----------------------------------------------------FILTER--------------------------------------------------

const EscalationFilter = props => {
	const translate = useTranslate();
	const locale = useLocale();
	const record = useRecordContext(); // todo: change all @https://marmelab.com/react-admin/Upgrade.html#options-element-no-longer-receive-a-record

	return <Filter {...props}>
		<ReferenceArrayInput label={translate('resources.escalations.filter.divisions')}
			reference="divisions"
			source="divisionsIds"
		>
			<AutocompleteArrayInput optionText="name" filterToQuery={divisionQuery} />
		</ReferenceArrayInput>
		<ReferenceArrayInput label={translate('resources.escalations.filter.countries')}
			reference="countries"
			source="countriesIds"
		>
			<AutocompleteArrayInput optionText="name" filterToQuery={countryQuery} />
		</ReferenceArrayInput>
		<ReferenceInput source="categoryId"
			reference="categories"
		>
			<AutocompleteInput optionText="nameEn" filterToQuery={categoryQuery} />
		</ReferenceInput>
		<ReferenceInput label={translate('resources.escalations.filter.solution')}
			reference="solutionMetadatas"
			source="solution"
		>
			<AutocompleteInput optionText="name" filterToQuery={solutionQuery} />
		</ReferenceInput>
		<TextInput
			label={translate(`resources.escalations.filter.description`)}
			source={`description.${locale}`}
		/>
		<TextInput
			label={translate(`resources.escalations.filter.majorTrigger`)}
			source={`majorTrigger.${locale}`}
		/>
		<TextInput
			label={translate(`resources.escalations.filter.impact`)}
			source={`impact.${locale}`}
		/>
		<ReferenceInput source="primaryContactId"
			reference="serviceTeams"
		>
			<AutocompleteInput optionText="it4youTeam" filterToQuery={serviceTeamQuery} />
		</ReferenceInput>
		<ReferenceArrayInput label={translate('resources.escalations.filter.businessImpactManagersIds')}
			source="businessImpactManagersIds"
			reference="businessImpactManagers"
		>
			<AutocompleteArrayInput optionText="description" filterToQuery={businessImpactManagerQuery} />
		</ReferenceArrayInput>
	</Filter>;
}
	;

// ----------------------------------------------------LIST----------------------------------------------------

export const EscalationDraftList = props => {
	const locale = useLocale();
	const translate = useTranslate();

	return <List filters={<EscalationFilter />}
		actions={<ListActions {...props} />}
		{...props}>
		<Datagrid rowClick="edit">
			<ReferenceArrayField
				reference="divisions"
				source="divisionsIds">
				<SingleFieldList linkType={false}>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>
			<ReferenceField source="countryId" link={false}
				reference="countries">
				<TextField source="nameEn" />
			</ReferenceField>
			<ReferenceField source="categoryId" link={false}
				reference="categories">
				<TextField source="nameEn" />
			</ReferenceField>
			<ReferenceField source={`solution`} reference={"solutionMetadatas"} link={false}>
				<TextField source="name" />
			</ReferenceField>
			<TextField source={`description.${locale}`}
				label={translate('resources.escalations.descriptionShort')} />
			<ReferenceField source="primaryContactId" link={false}
				reference="serviceTeams">
				<TextField source="it4youTeam" />
			</ReferenceField>
			<ReferenceArrayField
				source="businessImpactManagersIds"
				reference="businessImpactManagers">
				<SingleFieldList linkType={false}>
					<ChipField source="description" />
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	</List>;
};

// ----------------------------------------------------Detailed NEW------------------------------------------

const GetSteps = () => {
	const translate = useTranslate();

	return [
		translate('resources.escalations.steps.countryDivisions'),
		translate('resources.escalations.steps.processSystemApplication'),
		translate('resources.escalations.steps.description'),
		translate('resources.escalations.steps.impact'),
		translate('resources.escalations.steps.businessCriticalTime'),
		translate('resources.escalations.steps.contacts'),
		translate('resources.escalations.steps.linkResources'),
		translate('resources.escalations.steps.mailTemplate'),
		translate('resources.escalations.steps.sMSTemplate')
	];
}

const StepActionsButtons = ({ activeStep, setActiveStep, isEditMode, canGoNextValidators }) => {
	const classes = useStyles();
	const steps = GetSteps();

	function handleNext() {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	}

	function handleBack() {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	}

	return (
		<div>
			<Button
				disabled={activeStep === 0}
				onClick={handleBack}
				className={classes.button}
			>
				Back
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleNext}
				className={classes.button}
				disabled={isEditMode ? false : !canGoNextValidators.every(validator => validator && validator.length)}
			>
				{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
			</Button>
		</div>
	)
}


const oldRecordValue = (record, oldRecord, entity, field, subfield) => {
	if (!record.draftFor) return;

	let newValue;
	let oldValue;

	if (oldRecord[entity].constructor === Array) {
		if (!oldRecord[entity][0]) return;
		// TODO check divisions case (if another division added, we check just for the first in the array)
		newValue = record[entity][0][field];
		oldValue = oldRecord[entity][0][field];
	} else {
		if (entity === 'solutionObject') {
			newValue = `${record[entity]['displayId']} - ${record[entity][field]}`
			oldValue = `${oldRecord[entity]['displayId']} - ${oldRecord[entity][field]}`
		} else {
			newValue = record[entity][field];
			oldValue = oldRecord[entity][field];
		}
	}

	if (subfield) {
		newValue = newValue[subfield]
		oldValue = oldValue[subfield]
	}

	return (
		<div className="old-values">
			{newValue !== oldValue && oldValue}
		</div>
	)
}

const EscalationToolbar = props => {
	const record = useRecordContext();
	const redirect = useRedirect();
	const { permissions: ccAdmin } = usePermissions();
	const notify = useNotify();


	async function handleApprove(draftId) {
		const updatedEscalation = await fetch(config.apiUri + `/escalations-draft/approve/${draftId}`, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
			},
			body: JSON.stringify({
				isApproved: true
			})
		});

		const status = updatedEscalation.status;

		if (status === 403) {
			notify('unauthorized', { messageArgs: { status } });
			return;
		}

		if (status === 200) {
			notify('escalationApprove');
			redirect("/escalations")
		}
	};

	const [update, { isLoading: approveIsLoading }] = useUpdate(
		"escalationDrafts",
		{
			id: record.id,
			data: {
				...record,
			},
			previousData: record
		}, {
		onSettled: () => {
			redirect("/escalationDrafts");
		}
	});

	const [deleteOne, { isLoading: disapproveIsLoading }] = useDelete("escalationDrafts",
		{
			id: record.id,
			previousData: record
		}, {
		onSettled: () => {
			redirect("/escalationDrafts");
		}
	});

	return <Toolbar style={{ justifyContent: "space-between", display: "" }}>
		<Button
			label="Approve"
			color="primary"
			variant="contained"
			onClick={() => handleApprove(record.id)}
			disabled={approveIsLoading}
		>Approve</Button>

		<SaveButton
			label="Save"
			redirect="list"
			disabled={props.invalid}
		/>

		<Button
			label="Delete Draft (Decline)"
			color="error"
			variant="outlined"
			onClick={() => deleteOne()}
			disabled={disapproveIsLoading}
		>Delete Draft (Decline)</Button>
	</Toolbar>
};

const GetStepContent = ({
	step,
	classes,
	activeStep,
	setActiveStep,
	isEditMode,
	translationLanguage,
	setTranslationLanguage
}) => {
	const values = useWatch();
	const translate = useTranslate();
	const locale = useLocale();
	const record = useRecordContext();
	const translationLangs = languageCodes.map(code => ({ code, 'name': translate(`languages.${code}`) }));
	const { permissions: ccAdmin } = usePermissions();

	const validateProp = (prop) => {
		if (!isEditMode) {
			return values?.[prop]?.en;
		} else {
			return values[prop][locale];
		}
	}

	const { setValue } = useFormContext();

	const handleBlur = (e) => {
		setValue(e.target.name, prependHttps(e.target.value), { shouldDirty: true });
	}

	const businessCriticalTimesValidator = [];

	if (step === 4) {
		if (values.businessCriticalTimes && values.businessCriticalTimes.length) {
			values.businessCriticalTime = values.businessCriticalTimes[0];
		}

		if (values.businessCriticalTimes) {

			if (values.businessCriticalTimes.length) {
				values.businessCriticalTimes.map(group => {

					if (group && Object.keys(group).length > 0 && group.constructor === Object) {

						if (group?.time?.from && group?.time?.to && group?.date?.from && group.date?.to) {
							businessCriticalTimesValidator.push('123');
						} else {
							businessCriticalTimesValidator.push('');
						}
					} else {
						businessCriticalTimesValidator.push('');
					}
				});
			} else {
				businessCriticalTimesValidator.push('');
			}
		}
	}

	// TWO options to handle this:
	// let { data: oldRecord, isLoading, error } = useGetOne("escalations", {id: record.draftFor ? record.draftFor : ''});
	let { data: oldRecord, isLoading, error } = useGetOne("escalations", { id: record.draftFor }, { retry: false });
	if (error) { isLoading = false }

	switch (step) {
		case 0:
			return (
				<div className="container-fluid">
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.countrySelection')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "country", "nameEn")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceInput className="col-12"
								source="countryId"
								reference="countries"

							>
								<AutocompleteInput optionText="nameEn" filterToQuery={nameEnQuery} validate={required()} />
							</ReferenceInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Country"
								reference="countries"
								source="countryId"
								content={CreateEditFormCountry} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.divisionsSelection')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "divisions", "name")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="Divisions"
								reference="divisions"
								source="divisionsIds"

								validate={required()}
								fullWidth>
								<AutocompleteArrayInput optionText="name" filterToQuery={divisionQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Division"
								reference="divisions"
								source="divisionsIds"
								isarrayinput="true"
								content={CreateEditFormDivision} />
						} */}
					</div>

					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[values.countryId, values.divisionsIds]}/>*/}
				</div>
			);
		case 1:
			return (
				<div className="container-fluid">
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.processSystemApplication')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "category", "nameEn")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceInput className="col-12"
								source="categoryId"
								reference="categories"
								label="process / system / application"
							>
								<AutocompleteInput optionText="nameEn" filterToQuery={categoryQuery} validate={required()} fullWidth />
							</ReferenceInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="process / system / application"
								reference="categories"
								source="categoryId"
								content={CreateEditFormCategory} />
						} */}
					</div>
					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[values.categoryId]}/>*/}
				</div>
			)
		case 2:
			return (
				<div>
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.language')}</Typography>
					{isEditMode &&
						<Select value={translationLanguage} onChange={(e) => setTranslationLanguage(e.target.value)}>
							{translationLangs.map(lang => <MenuItem key={lang.code} value={lang.code}>
								{lang.name}
							</MenuItem>)}
						</Select>}

					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.majorTrigger')}</Typography>
							{!isLoading && oldRecordValue(record, oldRecord, "majorTrigger", translationLanguage)}
							<TextInput source={`majorTrigger.${translationLanguage}`}
								label=""
								multiline
								fullWidth />
						</>
					}

					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.englishMajorTrigger')}</Typography>
						<TextInput source={`majorTrigger.en`}
								label=""
								multiline
								fullWidth />
						</>
					}

					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.description')}</Typography>
							{!isLoading && oldRecordValue(record, oldRecord, "description", translationLanguage)}
							<TextInput source={`description.${translationLanguage}`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}

					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.englishDescriptionEscalation')}</Typography>
							<TextInput source={`description.en`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.solution')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "solutionObject", "name")}
					<ReferenceInput source="solution"
						reference="solutionMetadatas"
						label="solution-/application name"
					>
						<AutocompleteInput optionText="name" filterToQuery={solutionQuery} validate={required()} fullWidth />
					</ReferenceInput>
					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[values.descriptionDe, values.descriptionEn]}/>*/}
				</div>
			)
		case 3:
			return (
				<div>
					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.impact')}</Typography>
							{!isLoading && oldRecordValue(record, oldRecord, "impact", translationLanguage)}
							<TextInput source={`impact.${translationLanguage}`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}
					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.impactEn')}</Typography>
							<TextInput source={`impact.en`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}

					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[values.impactDe, values.impactEn]}/>*/}
				</div>
			)
		case 4:
			return (
				<div>
					<ArrayInput source="businessCriticalTimes">
						<SimpleFormIterator>
							<p>{translate('resources.escalations.businessCriticalTime')}</p>
							{!isLoading && oldRecordValue(record, oldRecord, "businessCriticalTimes", "time", "from")}
							<TextInput label="From (Time)"
								source="time.from"
								type="time"
								validate={required()}
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							{!isLoading && oldRecordValue(record, oldRecord, "businessCriticalTimes", "time", "to")}
							<TextInput label="To (Time)"
								source="time.to"
								type="time"
								validate={required()}
								formClassName={classes.inlineBlock}
								style={{ width: '12rem' }} />

							<p>{translate('resources.escalations.businessCriticalDays')}</p>
							{!isLoading && oldRecordValue(record, oldRecord, "businessCriticalTimes", "date", "from")}
							<SelectInput
								label="From (Date)"
								source="date.from"
								validate={required()}
								formClassName={classes.inlineBlock}
								choices={days}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							{!isLoading && oldRecordValue(record, oldRecord, "businessCriticalTimes", "date", "to")}
							<SelectInput
								label="To (Date)"
								source="date.to"
								validate={required()}
								formClassName={classes.inlineBlock}
								choices={days}
								style={{ width: '12rem' }} />
						</SimpleFormIterator>
					</ArrayInput>
					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[*/}
					{/*  values.businessCriticalTime?.time?.from,*/}
					{/*  values.businessCriticalTime?.time?.to,*/}
					{/*  values.businessCriticalTime?.date?.from,*/}
					{/*  values.businessCriticalTime?.date?.to*/}
					{/*]}/>*/}
				</div>
			)
		case 5:
			return (
				<div className="container-fluid">

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.primaryContactId')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "primaryContact", "remarkEn")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceInput className="col-12"
								source="primaryContactId"
								reference="serviceTeams"
								label="The primary responsible contact"
							>
								<AutocompleteInput optionText="it4youTeam" filterToQuery={serviceTeamQuery} validate={required()} fullWidth />
							</ReferenceInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Primary Contact"
								reference="serviceTeams"
								source="primaryContactId"
								content={ServiceTeamForm} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.secondaryContactsIds')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "secondaryContacts", "remarkEn")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="The secondary responsible contacts"
								reference="serviceTeams"
								source="secondaryContactsIds"

								fullWidth>
								<AutocompleteArrayInput optionText="it4youTeam" filterToQuery={serviceTeamQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Secondary Contact"
								reference="serviceTeams"
								source="secondaryContactsIds"
								isarrayinput="true"
								content={ServiceTeamForm} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.businessImpactManagersIds')} </Typography>
					{!isLoading && oldRecordValue(record, oldRecord, "businessImpactManagers", "description")}
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="Business Impact Managers"
								source="businessImpactManagersIds"
								reference="businessImpactManagers"

								validate={required()}
								fullWidth>
								<AutocompleteArrayInput optionText="description" filterToQuery={businessImpactManagerQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Business Impact Manager"
								reference="businessImpactManagers"
								source="businessImpactManagersIds"
								isarrayinput="true"
								content={BusinessImpactManagerForm} />
						} */}
					</div>

					{/* // can be removed, does not seem to be needed
                    <Typography variant="subtitle1"
                                style={{fontWeight: 600}}> Responsible Person </Typography>
                    <TextInput source="responsiblePerson"
                               label=""
                               multiline
                               fullWidth/>

                    <Typography variant="subtitle1"
                                style={{fontWeight: 600}}> Responsible Group </Typography>
                    <TextInput source="responsibleGroup"
                               label=""
                               multiline
                               fullWidth/>
*/}

					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[*/}
					{/*  values.primaryContactId,*/}
					{/*  values.businessImpactManagersIds*/}
					{/*]}/>*/}
				</div>
			);
		case 6:
			return (
				<div>
					<ArrayInput source="linkResources">
						<SimpleFormIterator>
							<TextInput label=""
								source="url"
								type="url"
								placeholder="https://mydomain.com"
								validate={required()}
								onBlur={handleBlur}
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							<TextInput label=""
								source="name"
								placeholder="name"
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
						</SimpleFormIterator>
					</ArrayInput>

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[]} />
					}
				</div>
			);
		case 7:
			return (
				<div>
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.mailSubjectDe')} </Typography>
					<TextInput source="mailSubjectDe"
						label=""
						fullWidth />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.mailSubjectEn')} </Typography>
					<TextInput source="mailSubjectEn"
						label=""
						fullWidth />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.mailTextDe')} </Typography>
					<TextInput source="mailTextDe"
						label=""
						fullWidth
						multiline />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.mailTextEn')} </Typography>
					<TextInput source="mailTextEn"
						label=""
						fullWidth
						multiline />

					{/*<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep} isEditMode={isEditMode} canGoNextValidators={[]}/>*/}
				</div>
			);
		case 8:
			return (
				<div>
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.smsTextDe')}</Typography>
					<TextInput source="smsTextDe"
						label=""
						fullWidth
						multiline />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.smsTextEn')}</Typography>
					<TextInput source="smsTextEn"
						label=""
						fullWidth
						multiline />

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[]} />
					}
				</div>
			);
		default:
			return 'Unknown step';
	}
}

export const VerticalStepperForm = props => {
	const classes = useStyles();
	const steps = GetSteps();

	const locale = useLocale();
	const [translationLanguage, setTranslationLanguage] = useState(locale);

	return (
		<div className={classes.root}>
			<Stepper activeStep={0}
				orientation="vertical">
				{steps.map((label, index) => (
					<Step key={label} active={true}>
						{/* THIS BUTTON IS FOR RESETTING THE STEP; NOT TO GO TO NEXT STEP*/}
						<StepButton>
							{label}
						</StepButton>
						<StepContent>
							<GetStepContent step={index} classes={classes} isEditMode={true}
								translationLanguage={translationLanguage}
								setTranslationLanguage={setTranslationLanguage} />
						</StepContent>
					</Step>
				))}
			</Stepper>
			<Paper square
				elevation={0}
				className={classes.resetContainer}>
				{/*<EscalationToolbar handleReset={handleReset}/>*/}
			</Paper>
		</div>
	);
}

// ----------------------------------------------------CREATE------------------------------------------------

// The creation is done via escalation, not draft

// -------------------------------------------------- -EDIT----------------------------------------------------
const PostEditActionsInEscalationView = ({ basePath, data, resource }) => <TopToolbar> <ShowButton
	record={data}
	label={'Show History'} /> </TopToolbar>;
export const EscalationDraftEdit = props => {
	const [activeStep, setActiveStep] = React.useState(0);
	const editMode = true;

	return (
		<Edit redirect="list" actions={<PostEditActionsInEscalationView />}
			title={<Title prefix="Edit" resource="Escalation" select={escalation} />} {...props}>
			<SimpleForm toolbar={<EscalationToolbar activeStep={activeStep} setActiveStep={setActiveStep}
				isEditMode={editMode} />}>
				<>
					<VerticalStepperForm redirect="list" props isEditMode={editMode} activeStep={activeStep} setActiveStep={setActiveStep} />
				</>
			</SimpleForm>
		</Edit>
	);
};
