import * as React from 'react';
import { useLocale, useSetLocale, SelectInput } from 'react-admin';
import { Select, MenuItem } from '@mui/material';
import './localeSwitcher.css'
import { useTranslate } from 'react-admin';
import { languageCodes } from '../resources/translations/languageCodes';

const LocaleSwitcher = () => {
    const locale = useLocale();
    const setLocale = useSetLocale();
    const currentLang = locale;
    const translate = useTranslate();

    const handleChange = (event) => {
        setLocale(event.target.value)
    }
    return (
        <div>
            <Select className={'languages-switcher-dropdown'} value={currentLang} onChange={handleChange} variant="standard">
                {
                    languageCodes.map(code =>
                      <MenuItem key={code} value={code}>
                          <img src={`/languages/${code}.svg`} alt={`languages.${code} flag`} className="flag" />
                          {translate(`languages.${code}`)}
                      </MenuItem>)
                }
            </Select>
        </div>
    );
};

export default LocaleSwitcher;
