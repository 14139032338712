import { UserManager } from 'oidc-client-ts';
import { getToken, removeToken } from '../common/functions'
import settings from '../common/config'

const userManager = new UserManager({
    authority: settings.issuer,
    client_id: settings.clientId,
    redirect_uri: settings.redirectUri,
    response_type: 'code',
    scope: 'openid siam',
});

const oidcConfig = {
    scope: 'openid siam',
    response_type: 'code',
    redirect_uri: settings.redirectUri,
    client_id: settings.clientId,
    state: '123'
}

const encodeUrl = object => Object.entries(object).map(([key, value]) => `${key}=${value}`).join('&');

const codeGrantUrlEncoded = encodeUrl(oidcConfig)

const checkToken = () => getToken() && Promise.resolve() || Promise.reject({ message: false })

const cleanup = () => {
    window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
    );
}

export default {
    login: async (params = {}) => {
        if (params.completeLogin) {
            await userManager.clearStaleState();
            cleanup();
            return Promise.resolve({ redirectTo: '/review-dashboard' })
        }
        userManager.signinRedirect();
        return;
    },

    logout: (params = {}) => {
        removeToken()
        localStorage.removeItem('user')
        return Promise.resolve();
    },

    checkAuth: (params = {}) => {
        // return Promise.resolve();
        return checkToken();
    },

    checkError: (error = {}) => {
        const status = error.status;
        if (status === 403) {
            return alert("You are not authorized for this action.");
        } else if (status === 409) {
            return alert("This escalation already has a draft, therefore deleting, direct editing and creating new drafts are forbidden.");
        } else if (status === 401 || status === 502) {
            removeToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: async (params = {}) => {
        const token = getToken();

        const response = await fetch(`${settings.apiUri}/user-info-role`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });
        const responseBody = JSON.parse(await response.text());
        const ccAdmin = responseBody.ccAdmin.toString();
        return responseBody ? Promise.resolve(ccAdmin) : Promise.reject();
    },

    getIdentity: () => {
        try {
            const { email } = JSON.parse(localStorage.getItem('user'));
            return Promise.resolve({ fullName: email, email });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
