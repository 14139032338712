import React from 'react';
import {MenuItemLink, UserMenu, useTranslate, Logout} from 'react-admin';

// Custom User Menu
// @see https://marmelab.com/blog/2019/03/07/react-admin-advanced-recipes-user-profile.html
const MyUserMenuView = (props) => {
    const {profile} = props;
    const translate = useTranslate();

    const icon = profile && profile.picture ? <img alt="" src={profile.picture} width="48" height="48"/> : undefined

    return (
        <UserMenu label={profile ? profile.name : 'Unknown'} icon={icon} children={[
            <MenuItemLink key="email-settings" to={"/email-settings"} primaryText={translate("emailSettings.heading")}></MenuItemLink>,
            <Logout key="logout" />
        ]}/>
    );
}

// const mapStateToProps = state => {
//     const resource = 'profile';
//     const id = 'my-profile';
//     const profileState = state.admin.resources[resource];
//
//     return {
//         profile: profileState ? profileState.data[id] : null
//     };
// };

// const MyUserMenu = connect(
//     mapStateToProps,
//     {useGetOne}
// )(MyUserMenuView);
// export default MyUserMenu;
export default MyUserMenuView;
