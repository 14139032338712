import React from "react";
import {Admin, Layout, CustomRoutes, resolveBrowserLocale, Resource, useTranslate} from 'react-admin';
import { Route } from "react-router-dom";
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import dataProvider from './providers/dataProvider';
import {Menu} from "./components/menu";
import MyAppBar from "./components/appBar";
import EmailSettings from "./components/EmailSettings";
import localEnglishMessages from "./components/resources/translations/en";
import localGermanMessages from "./components/resources/translations/de";
import authProvider from "./providers/authProvider";
import MyLoginPage from "./components/MyLoginPage";
import {UserCreate, UserEdit, UserList} from './components/resources/user';
import {CategoryCreate, CategoryEdit, CategoryList} from './components/resources/category';
import {CountryCreate, CountryEdit, CountryList} from './components/resources/country';
import {
  BusinessImpactManagerCreate,
  BusinessImpactManagerEdit,
  BusinessImpactManagerList
} from './components/resources/businessImpactManager';
import {DivisionCreate, DivisionEdit, DivisionList} from './components/resources/division';
import {DutyCreate, DutyEdit, DutyList} from './components/resources/duty';
import {EscalationCreate, EscalationEdit, EscalationList} from './components/resources/escalation';
import {EscalationDraftEdit, EscalationDraftList} from './components/resources/escalationDraft';
import { ServiceTeamCreate, ServiceTeamEdit, ServiceTeamList } from './components/resources/serviceTeam';
import { TeamCategoryCreate, TeamCategoryEdit, TeamCategoryList } from './components/resources/teamCategory';
import { ChangeHistoryList } from "./components/resources/changeHistory";
import ReviewDashboard from './components/customRoutes/ReviewDashboard';
import HiddenEscalation from './components/customRoutes/HiddenEscalation';
import { DeclineCreate, DeclineEdit, DeclineList } from './components/resources/decline';

const MyLayout = props => {
  return <Layout {...props} menu={Menu} appBar={MyAppBar} />
};

const messages = {
  en: { ...englishMessages, ...localEnglishMessages },
  de: { ...germanMessages, ...localGermanMessages },
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  resolveBrowserLocale()==="de"?"de":"en",
  [],
  { allowMissing: true });

const App = () => {
  const translate = useTranslate();

  return <Admin
    layout={MyLayout}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    loginPage={MyLoginPage}
  >
    <Resource name="escalations" options={{ label: translate('resources.menu.escalations') }} list={EscalationList} edit={EscalationEdit} create={EscalationCreate} />
    <Resource name="countries" options={{ label: translate('resources.menu.countries') }} list={CountryList} edit={CountryEdit} create={CountryCreate} />
    <Resource name="divisions" options={{ label: translate('resources.menu.divisions') }} list={DivisionList} edit={DivisionEdit} create={DivisionCreate} />
    <Resource name="businessImpactManagers" options={{ label: translate('resources.menu.businessImpactManagers') }} list={BusinessImpactManagerList} edit={BusinessImpactManagerEdit} create={BusinessImpactManagerCreate} />
    <Resource name="categories" options={{ label: translate('resources.menu.categories') }} list={CategoryList} edit={CategoryEdit} create={CategoryCreate} />
    <Resource name="escalationDrafts" options={{label: translate('resources.menu.escalationDrafts')}} list={EscalationDraftList} edit={EscalationDraftEdit}/>
    <Resource name="solutionMetadatas" />
    <Resource name="serviceTeams" options={{ label: translate('resources.menu.serviceTeams') }} list={ServiceTeamList} edit={ServiceTeamEdit} create={ServiceTeamCreate} />
    <Resource name="teamCategories" options={{ label: translate('resources.menu.teamCategories') }} list={TeamCategoryList} edit={TeamCategoryEdit} create={TeamCategoryCreate} />
    <Resource name="users" options={{ label: translate('resources.menu.users') }} list={UserList} edit={UserEdit} create={UserCreate} />
    <Resource name="duties" options={{ label: translate('resources.menu.duties') }} list={DutyList} edit={DutyEdit} create={DutyCreate} />
    <Resource name="changeHistory" options={{ label: translate('resources.menu.changeHistory') }} list={ChangeHistoryList} show={ChangeHistoryList} />
    <Resource name="declines" options={{ label: translate('resources.menu.declines') }} list={DeclineList} edit={DeclineEdit} create={DeclineCreate} />

    <CustomRoutes>
      <Route path="/email-settings" element={<EmailSettings/>}/>
      <Route path="/review-dashboard" element={<ReviewDashboard/>}/>
			<Route path="/hidden-escalations" element={<HiddenEscalation/>} />
    </CustomRoutes>
  </Admin>
};

export default App;
