import React from 'react';
import {
	List, Resource, Datagrid,
	SingleFieldList, ChipField, ReferenceField, TextField, ReferenceArrayField,
	useRecordContext, useRedirect, useTranslate, useLocale
} from 'react-admin';
import { IconButton } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';


const openEskaDetailPage = async (redirect, escalationRecord) => {
	redirect('edit', 'escalations', escalationRecord.id);
}

export const HiddenListItemActions = props => {
	const escalationRecord = useRecordContext();
	const redirect = useRedirect();

	return (
		<div>
			<IconButton onClick={(e) => openEskaDetailPage(redirect, escalationRecord)}>
				<FindInPageIcon color="action" />
			</IconButton>
		</div>
	);
}

export const HiddenList = props => {
	const locale = useLocale();
	const translate = useTranslate();

	return (
		<List title=" " exporter={false} {...props} >
			<Datagrid bulkActionButtons={false}>
				<ReferenceField source={`solution`} reference={"solutionMetadatas"} link={false} sortBy="solutionObject.name">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceArrayField reference="divisions" source="divisionsIds" sortBy="divisions.name">
					<SingleFieldList linkType={false}>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField reference="countries" source="countriesIds" sortBy="countries.nameEn">
					<SingleFieldList linkType={false}>
						<ChipField source="nameEn" />
					</SingleFieldList>
				</ReferenceArrayField>
				<TextField source={`description.${locale}`} label={translate('resources.escalations.descriptionShort')} sortBy={`description.${locale}`} />
				<HiddenListItemActions esca={props} />
			</Datagrid>
		</List>
	)
};

const HiddenEscalation = (props) => {
	const translate = useTranslate();

	return (
		<div>
			<Resource name="hidden-eska/list" list={HiddenList} />
		</div>
	);
};

export default HiddenEscalation;
