import React from 'react';
import {
	Create, Edit, Filter, List,
	Datagrid, DateField, ReferenceField,
	TextField, BooleanField,
	TextInput, BooleanInput, SelectInput,
	TopToolbar, ShowButton, SimpleForm,
	Labeled,
	useCreate, useRedirect, useTranslate, useRecordContext, useLocale,
	required
} from 'react-admin';
import { Title, QueryableTextInput } from '../common';
import { ListActions } from './utils/pdfDownload';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import config from '../../common/config';
import { query } from "../../common/functions"
import { States } from '../../common/enums';


const userQuery = query("emailAddress")

const fetchDecline = (escalationId) => {
	fetch(config.apiUri + `/escalations-review/decline/${escalationId}`, {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
		},
		body: JSON.stringify({
			state: States.RED
		})
	});
};

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();

	return <TopToolbar> <ShowButton
		record={data}
		label={translate('showHistory')} /> </TopToolbar>;
}

const DeclineFilter = props => {
	const record = useRecordContext();
	return <Filter {...props}>
		<SelectInput source="isResolved" choices={[true, false].map(x => ({ id: x, name: x }))} />
		<QueryableTextInput source="title" />
	</Filter>
}

export const DeclineList = props => {
	const locale = useLocale();

	return (
		<List
			filters={<DeclineFilter />}
			actions={<ListActions {...props} />}
			{...props}>
			<Datagrid rowClick="edit">
				<ReferenceField source="escalationId" reference="escalations">
					<TextField source={`description.${locale}`} />
				</ReferenceField>
				<TextField source="userEmail" />
				<TextField source="title" />
				<BooleanField source="isResolved" />
				<DateField source="declineAt"
					showDate />
			</Datagrid>
		</List>
	)
};

const DeclineFormEdit = props => {
	const locale = useLocale();
	const { isResolved } = useRecordContext()

	return isResolved
		? (
			<SimpleForm {...props} toolbar={false}>
				<Labeled label="Escalation"><TextField source={`escalation.description.${locale}`} /></Labeled>
				<Labeled label="User Email"><TextField source="userEmail" /></Labeled>
				<Labeled label="Declined At"><DateField source="declineAt" showTime /></Labeled>
				<Labeled label="Title"><TextField source="title" /></Labeled>
				<Labeled label="Reason"><TextField source="reason" /></Labeled>
				<Labeled label="Resolved"><TextField source="isResolved" /></Labeled>
			</SimpleForm>
		)
		: (
			<SimpleForm {...props}>
				<Labeled label="Escalation">
					<TextField source={`escalation.description.${locale}`} />
				</Labeled>
				<Labeled label="User Email">
					<TextField source="userEmail" />
				</Labeled>
				<Labeled label="Declined At">
					<DateField source="declineAt" showTime />
				</Labeled>
				<TextInput source="title"
					validate={required()} />
				<TextInput source="reason"
					validate={required()} fullWidth multiline={true} />
				<BooleanInput source="isResolved"
					validate={required()} label="Resolved" />
			</SimpleForm>
		)
};

const DeclineFormCreate = props => {
	const [create] = useCreate();
	const redirect = useRedirect();
	const location = useLocation();
	const { escalation_id } = parse(location.search);


	const postCreate = (data) => {
		data = {
			...data,
			escalationId: escalation_id,
			declineAt: new Date(Date.now())
		};
		create('declines', { data });
		fetchDecline(escalation_id);
		redirect('/declines');
	};

	return (
		<SimpleForm
			onSubmit={postCreate}
			{...props}>
			<TextInput source="title"
				validate={required()} fullWidth />
			<TextInput source="reason"
				validate={required()} fullWidth multiline={true} />
		</SimpleForm>
	)
}


const decline = _ => 'Decline'

export const DeclineEdit = props => {
	const translate = useTranslate();

	return (
		<Edit actions={<PostEditActions />} title={<Title prefix={translate('edit')} resource="Decline" select={decline} />} {...props}>
			<DeclineFormEdit />
		</Edit>
	)
};

export const DeclineCreate = props => {
	const translate = useTranslate();

	return (
		<Create redirect="list" title={<Title prefix={translate('create')} resource="Decline" select={decline} />} {...props}>
			<DeclineFormCreate />
		</Create>
	)
};
