import React from 'react';
import { listTimeZones } from 'timezone-support'
import {
	Edit, Create, Filter,
	List, Datagrid, SimpleForm, TopToolbar,
	TextField,
	AutocompleteInput, TextInput,
	required,
	ShowButton,
	usePermissions, useTranslate
} from 'react-admin';
import { Title, QueryableTextInput } from '../common'
import { query } from "../../common/functions";
import { ListActions } from './utils/pdfDownload';

const timeZones = listTimeZones().map(x => ({ id: x, name: x }))
const timeZoneQuery = query("timeZone")

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();
	return <TopToolbar> <ShowButton record={data} label={translate('showHistory')} /> </TopToolbar>;
};

const TeamCategoryFilter = props => (
	<Filter {...props}>
		<QueryableTextInput source="name" />
		<AutocompleteInput choices={timeZones} source="timeZone" filterToQuery={timeZoneQuery} />
	</Filter>
);

export const TeamCategoryList = () => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;
	return (
		<List filters={<TeamCategoryFilter />}
			actions={<ListActions />}
		>
			<Datagrid rowClick="edit">
				<TextField source="name" />
				<TextField source="timeZone" />
			</Datagrid>
		</List>
	)
};

const name = x => x.name

const TeamCategoryForm = props => (
	<SimpleForm {...props}>
		<TextInput source="name" validate={required()} />
		<AutocompleteInput choices={timeZones} source="timeZone" validate={required()} filterToQuery={timeZoneQuery} />
	</SimpleForm>
)

export const TeamCategoryEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return <Edit actions={<PostEditActions />} title={<Title prefix={translate('edit')} resource="Team Category" select={name} />} {...props}>
		<TeamCategoryForm />
	</Edit>;
};

export const TeamCategoryCreate = props => {
	const translate = useTranslate();
	return <Create redirect="list" title={<Title prefix={translate('create')} resource="Team Category" select={name} />} {...props}>
		<TeamCategoryForm />
	</Create>;
};
