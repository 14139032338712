import React from 'react'
import { AppBar } from 'react-admin';
import { Box, Typography } from '@mui/material';
import LocaleSwitcher from './localeSwitcher/localeSwitcher';
import MyUserMenu from "./MyUserMenu";

const MyAppBar = props => (
    <AppBar userMenu={<MyUserMenu/>}>
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>
        <LocaleSwitcher/>
    </AppBar>
  );

  export default MyAppBar;
