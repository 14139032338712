import {useResourceDefinitions, useSidebarState, MenuItemLink, usePermissions} from "react-admin";
import React, {useEffect} from "react";
import {useMediaQuery} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import PublicIcon from '@mui/icons-material/Public';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ListIcon from '@mui/icons-material/List';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AlarmIcon from '@mui/icons-material/Alarm';
import {useTranslate} from 'react-admin';
import './../App.css';


const menuSelection = (menuItem) => {
    switch (menuItem) {
        case 'countries':
            return { icon: <PublicIcon />, parent: 'General' };
        case 'divisions':
            return { icon: <BusinessIcon />, parent: 'General' };
        case 'businessImpactManagers':
            return { icon: <PeopleOutlineIcon />, parent: 'General' };
        case 'categories':
            return { icon: <CategoryIcon />, parent: 'Escalations' };
        case 'escalations':
        case 'escalationDrafts':
            return { icon: <AssistantIcon />, parent: 'Escalations' };
				case 'hiddenEscalations':
						return { icon: <AssistantIcon style={{ color: 'red' }}/>, parent: 'Escalations'};
				case 'declines':
					return { icon: <ThumbDownAltIcon />, parent: 'Escalations' };
        case 'hourlyAvailabilities':
            return { icon: <CalendarTodayIcon />, parent: 'ServiceTeams' };
        case 'dailyAvailabilities':
            return { icon: <CalendarTodayIcon />, parent: 'ServiceTeams' };
        case 'teamAvailabilities':
            return { icon: <CalendarTodayIcon />, parent: 'ServiceTeams' };
        case 'teamCategories':
            return { icon: <CategoryIcon />, parent: 'ServiceTeams' };
        case 'serviceTeams':
            return { icon: <GroupWorkIcon />, parent: 'ServiceTeams' };
        case 'users':
            return { icon: <PeopleIcon />, parent: 'Duties' };
        case 'duties':
            return { icon: <TimelapseIcon />, parent: 'Duties' };
        case 'changeHistory':
            return { icon: <AccessTimeIcon />, parent: 'History' };
        case 'reviewDashboard':
            return { icon: <DashboardIcon />};
        default:
            return { icon: <AllInclusiveIcon />, parent: 'General' };
    }
};

export const Menu = ({onMenuClick, logout}) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [open] = useSidebarState();
    const resources = useResourceDefinitions();



    const translate = useTranslate();
    const { permissions: ccAdmin } = usePermissions();

    const Parents = {
        General: React.useState(true),
        Escalations: React.useState(true),
        ServiceTeams: React.useState(true),
        Duties: React.useState(true),
        History: React.useState(true),
    };

    const ccAdminOnly = [
        "countries", "divisions", "businessImpactManagers", "categories", "solutionMetadatas", "serviceTeams", "teamCategories", "users", "duties", "changeHistory"
    ];
    const ccAdminOnlyParents = [
        "General", "ServiceTeams", "Duties", "History"
    ];
    const reviewDashboardselection = menuSelection('reviewDashboard');
		const hiddenEscalationsSelection = menuSelection('hiddenEscalations');

    return (
        <div className="app-menu-container">
            {Object.keys(Parents).map((parentName) => {
                if (ccAdminOnlyParents.includes(parentName) && ccAdmin !== "true") return;
                const collapsed = Parents[parentName][0];
                const setOpen = Parents[parentName][1];

                const handleClick = () => setOpen(!collapsed);

                return <List key={parentName}>
                    <ListItem
                        button
                        onClick={handleClick}>
                        <ListItemIcon title={parentName}>
                            <>
                                <ListIcon />
                            </>
                        </ListItemIcon>
                        <ListItemText primary={translate(`resources.menu.${parentName.toLowerCase()}_title`)} />
                        {collapsed ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                        in={collapsed}
                        timeout="auto"
                        unmountOnExit>
                        <List component="div">
                            {Object.keys(resources).map(name => {
                                const selection = menuSelection(name);
                                if (name === "solutionMetadatas") return;
                                if (ccAdminOnly.includes(name) && ccAdmin !== "true") return;

                                if (selection.parent === parentName) { 
                                    return <MenuItemLink
                                        key={name}
                                        to={`/${name}`}
                                        primaryText={translate(`resources.menu.${name}`)}
                                        leftIcon={
                                            selection.icon
                                        }
                                        onClick={onMenuClick}
                                        sidebarIsOpen={open}
                                    />;
                                }
                                return false;
                            })}
                        </List>
                    </Collapse>
                </List>;
            })}
            {isXSmall && logout}
            <MenuItemLink to={"/review-dashboard"} primaryText={translate("resources.menu.reviewDashboard")} leftIcon={reviewDashboardselection.icon}></MenuItemLink>
						<MenuItemLink to={"/hidden-escalations"} primaryText={translate("resources.menu.hiddenEscalations")} leftIcon={hiddenEscalationsSelection.icon}></MenuItemLink>
        </div>
    );
};
