import React from 'react';
import {
	Create, Edit, Filter,
	Datagrid, List, SimpleForm, TopToolbar,
	required,
	ShowButton,
	TextField,
	TextInput,
	usePermissions, useTranslate
} from 'react-admin';
import { QueryableTextInput, Title, } from '../common'
import { ListActions } from './utils/pdfDownload';

const PostEditActions = ({ basePath, data, resource }) => <TopToolbar> <ShowButton
	record={data}
	label={'Show History'} /> </TopToolbar>;

const DivisionFilter = props => {
	const translate = useTranslate();

	return <Filter {...props}>
		<QueryableTextInput source="name"
			label={translate('resources.divisions.filter.name')} />
		<QueryableTextInput source="abbreviation"
			label={translate('resources.divisions.filter.abbreviation')} />
	</Filter>
};

export const DivisionList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List filters={<DivisionFilter />}
			actions={<ListActions {...props} />}
			{...props}>
			<Datagrid rowClick="edit">
				<TextField source="name" />
				<TextField source="abbreviation" />
			</Datagrid>
		</List>
	)
};

const name = x => x.name

export const DivisionEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (<Edit actions={<PostEditActions />}
		title={<Title prefix={translate('edit')}
			resource="Division"
			select={name} />} {...props}>
		<CreateEditFormDivision />
	</Edit>
	)
};

export const DivisionCreate = props => {
	const translate = useTranslate();

	return (<Create redirect="list" title={<Title prefix={translate('edit')}
		resource="Division"
		select={name} />} {...props}>
		<CreateEditFormDivision />
	</Create>
	)
};
export const CreateEditFormDivision = props => {
	return (<SimpleForm {...props}>
		<TextInput source="name"
			validate={required()} />
		<TextInput source="abbreviation" />
	</SimpleForm>);
}
