import React, {cloneElement} from 'react';
import {Button, CreateButton, downloadCSV, ExportButton, sanitizeListRestProps, SaveButton, Toolbar, TopToolbar} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import IconCancel from "@mui/icons-material/Cancel";
import makeStyles from '@mui/styles/makeStyles';

const export1 = (posts) => {
  const postsForExport = posts.map(post => {
    return {
      ID: post.id,
      Description_EN: post.descriptionEn,
      Description_DE: post.descriptionDe,
      Country_EN: post.country.nameEn,
      Country_DE: post.country.nameDe,
      Divisions: post.divisions.map(div => div.name).toString(),
      Responsible_Person: post.responsiblePerson,
      Responsible_Group: post.responsibleGroup,
      Last_Updated_At: post.updatedAt
    };
  });
  jsonExport(postsForExport, {
    headers: ['ID', 'Description_EN', 'Description_DE', 'Country_EN', 'Country_DE', 'Divisions', 'Responsible_Person', 'Responsible_Group', 'Last_Updated_At'],
    rowDelimiter: ';'
  }, (err, csv) => {
    downloadCSV(csv, 'Escalations export #1');
  });
};

const export2 = (posts) => {
  const postsForExport = posts.map(post => {
    return {
      ID: post.id,
      Divisions: post.divisions.map(div => div.name).toString(),
      Affected_CI: post.affectedCi,
      Responsible_Person: post.responsiblePerson,
      Responsible_Group: post.responsibleGroup,
      Last_Updated_At: post.updatedAt
    };
  });
  jsonExport(postsForExport, {
    headers: ['ID', 'Divisions', 'Affected_CI', 'Responsible_Person', 'Responsible_Group', 'Last_Updated_At'],
    rowDelimiter: ';'
  }, (err, csv) => {
    downloadCSV(csv, 'Escalations export #2');
  });
};

const export3 = (posts) => {
  const exportArr = [];

  posts.forEach(post => {
    exportArr.push({
      ID: post.id,
      Contact: post.primaryContact.it4youTeam,
      Type: 'Primary Contact',
      Phone: post?.primaryContact?.availabilities.length ? post.primaryContact.availabilities.map(av => `"${av.phoneNumber}`) : ''
    })

    post.secondaryContacts.forEach(contact => {
      exportArr.push({
        ID: post.id,
        Contact: contact.it4youTeam,
        Type: 'Secondary Contact',
        Phone: contact?.availabilities.length ? contact.availabilities.map(av => `"${av.phoneNumber}`) : ''
      })
    })

    post.businessImpactManagers.forEach(bim => exportArr.push({
      ID: post.id,
      Contact: bim.description,
      Type: 'BIM',
      Phone: `"${bim.phoneNumber}`
    }))
  });
  jsonExport(exportArr, {
    headers: ['ID', 'Contact', 'Type', 'Phone'],
    rowDelimiter: ';'
  }, (err, csv) => {
    downloadCSV(csv, 'Escalations export #3');
  });
};

export const ListActions = ({
                              currentSort,
                              className,
                              resource,
                              filters,
                              displayedFilters,
                              exporter, // you can hide ExportButton if exporter = (null || false)
                              filterValues,
                              permanentFilter,
                              hasCreate, // you can hide CreateButton if hasCreate = false
                              basePath,
                              selectedIds,
                              onUnselectItems,
                              showFilter,
                              maxResults,
                              total,
                              ...rest
                            }) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{...filterValues, ...permanentFilter}}
      exporter={export1}
      maxResults={maxResults}
      label={'Export #1'}
    />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{...filterValues, ...permanentFilter}}
      exporter={export2}
      maxResults={maxResults}
      label={'Export #2'}
    />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{...filterValues, ...permanentFilter}}
      exporter={export3}
      maxResults={maxResults}
      label={'Export #3'}
    />
  </TopToolbar>
);
ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles((theme) => ({
  toolbarBottomButtons: {
    "justify-content": "space-between!important"
  }
}));

export const DialogToolbar = props => {
  const classStyles = useStyles();

  return <Toolbar className={classStyles.toolbarBottomButtons}>
    <Button
      label="ra.action.cancel"
      onClick={props.handleClose}
      disabled={props.loading}
    >
      <IconCancel />
    </Button>
    <SaveButton
      label="Save"
      redirect={false}
      submitOnEnter={false}
    />
  </Toolbar>
}
