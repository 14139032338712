import { fetchUtils } from 'react-admin';
import settings from '../common/config';
import { getToken } from '../common/functions';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = getToken() 
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const apiUrl = settings.apiUri

const transformMany = (from, to) => object => Object.entries(object).reduceRight((x, [key, value]) => {
    if (key === from){
        x[to] = value 
    } else {
        x[key] = value
    }
    return x
},{})

const transform = (from, to) => object => {
    if (object && object[from]){
        const value = object[from]
        delete object[from]
        object[to] = value
    }
    return object
}

const transformIncoming = transform("_id", "id")
const transformOutgoing = transform("id", "_id")

const transformKeyIncoming = value => value === "_id" ? "id" : value
const transformKeyOutgoing = value => value === "id" ? "_id" : value

const singletonData = ([datum]) => datum && { data: transformIncoming(datum) }
const collectionData =  data => ({ data: data && data.map(transformIncoming) || [] })

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if(params.filter['phoneNumber'] && params.filter['phoneNumber']['$regex']) {
            params.filter['phoneNumber']['$regex'] = params.filter['phoneNumber']['$regex'].replace('+', '');
        }

        const query = {
            sorting: { on: transformKeyOutgoing(field), order },
            range: { skip: (page - 1) * perPage, limit: perPage },
            filter: transformOutgoing(params.filter)
        };
        const url = `${apiUrl}/${resource}?query=${JSON.stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.map(transformIncoming),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getOne: (resource, { id }) => httpClient(`${apiUrl}/${resource}/${id}`).then(({ json }) => singletonData(json)),

    getMany: (resource, { ids }) => {
        ids = ids.map((input) => input === "object" ? input.id || input._id : input)
        const query = {
            filter: { _id: ids }
        };
        const url = `${apiUrl}/${resource}?query=${JSON.stringify(query)}`;
        return httpClient(url).then(({ json }) => collectionData(json));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sorting: { on: transformKeyOutgoing(field), order },
            range: { skip: (page - 1) * perPage, limit: perPage },
            filter: transformOutgoing({...params.filter, [params.target]: params.id})
        };
        const url = `${apiUrl}/${resource}?query=${JSON.stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.map(transformIncoming),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, {id, data}) => {
        if (resource === 'serviceTeams') {
            data.availabilities.map(availability => {
                delete availability["date.from"]
                delete availability["date.to"]
                delete availability["time.from"]
                delete availability["time.to"]
            });
        }

        return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(collectionData([data])),
        }).then(({ json }) => singletonData(json));
    },
    updateMany: (resource, {ids, data}) => {
        const query = {
            filter: { _id: ids},
            data: [transformOutgoing(data)]
        };
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: JSON.stringify(query),
        }).then(({ json }) => collectionData(json.map(x => x._id)));
    },

    create: (resource, { data }) => {
        if (resource === 'serviceTeams') {
            data.availabilities.map(availability => {
                delete availability["date.from"]
                delete availability["date.to"]
                delete availability["time.from"]
                delete availability["time.to"]
            });
        }
       return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(collectionData([data])),
        }).then(({json}) => singletonData(json)).catch(error => {
            return Promise.reject(error)
        })
    },

    delete: (resource, { id }) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
        }).then(({ json }) => singletonData(json)),

    deleteMany: (resource, { ids    }) => {
        const query = {
            filter: { _id: ids }
        };
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'DELETE',
            body: JSON.stringify(query),
        }).then(({ json }) => json && { data: json.map(x => x._id)});
    }
};
