import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {TextInput,} from 'react-admin';
import {like, unlike} from '../common/functions';


const styles = makeStyles({
    root: {
        display: 'none',
    }
});

export const Title = ({record, select, prefix, resource}) => <span>{`${prefix} ${record && select && select(record) || resource}`}</span>

export const QueryableTextInput = props => <TextInput parse={like}
                                                        format={unlike} {...props} />
