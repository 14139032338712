import React, { useState } from 'react';
import {
	Create, Edit, Filter,
	List, SingleFieldList, Datagrid, SimpleForm, Toolbar, TopToolbar, SimpleFormIterator,
	AutocompleteArrayInput, AutocompleteInput, ReferenceArrayInput, ReferenceInput, SelectInput, TextInput, ArrayInput,
	ReferenceArrayField, ReferenceField, TextField, ChipField, BooleanField,
	required,
	ShowButton, CloneButton, SaveButton, DeleteButton,
	useNotify, useRedirect, useGetOne, useRecordContext, usePermissions, useTranslate, useLocale
} from 'react-admin';
import { useParams, Link } from 'react-router-dom';
import { QueryableTextInput, Title, } from '../common';
import { query, prependHttps } from "../../common/functions";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import Paper from '@mui/material/Paper';
import Popup from "./popup";
import { CreateEditFormCountry } from "./country";
import { CreateEditFormDivision } from "./division"
import { CreateEditFormCategory } from "./category";
import { ServiceTeamForm } from './serviceTeam';
import { BusinessImpactManagerForm } from './businessImpactManager';
import EditIcon from '@mui/icons-material/Edit';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useFormContext, useWatch } from 'react-hook-form';
import { languageCodes } from './translations/languageCodes';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ListActions } from './utils/pdfDownload';
import config from '../../common/config';
import { States } from '../../common/enums';

const useStyles = makeStyles((theme) => ({
	inlineBlock: { display: 'inline-flex', marginRight: '2rem' },
	firstTextMargin: { marginBottom: '-1rem', marginTop: '0.8rem' },
	textMargin: { marginBottom: '-1rem', marginTop: '-0.8rem' },
	root: {
		width: '90%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	completed: {
		display: 'inline-block',
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	resetButton: {
		color: theme.palette.info.light
	}
}));

const categoryQuery = query("nameEn");
const divisionQuery = query("name");
const countryQuery = query("nameEn");
const nameEnQuery = query("nameEn");
const serviceTeamQuery = query("it4youTeam");
const solutionQuery = query("name");
const businessImpactManagerQuery = query("description");

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(x => ({
	id: x,
	name: x
}));
const escalation = _ => 'Escalation';

const AllCountriesSelectedMessage = props => {
	const escalationRecord = useRecordContext();
	return (
		escalationRecord.isAllCountries && <span style={{ fontWeight: "bold" }}>{(" All countries affected!")}</span>
	);
}

// ----------------------------------------------------FILTER--------------------------------------------------

const EscalationFilter = props => {
	const translate = useTranslate();
	const locale = useLocale();

	return <Filter {...props}>
		<ReferenceArrayInput label={translate('resources.escalations.filter.divisions')}
			reference="divisions"
			source="divisionsIds"
		>
			<AutocompleteArrayInput optionText="name" filterToQuery={divisionQuery} />
		</ReferenceArrayInput>
		<ReferenceArrayInput label={translate('resources.escalations.filter.countryId')}
			reference="countries"
			source="countriesIds"
		>
			<AutocompleteArrayInput optionText="nameEn" filterToQuery={countryQuery} />
		</ReferenceArrayInput>
		<ReferenceInput source="categoryId"
			reference="categories"
		>
			<AutocompleteInput optionText="nameEn" filterToQuery={categoryQuery} />
		</ReferenceInput>
		<ReferenceInput label={translate('resources.escalations.filter.solution')}
			reference="solutionMetadatas"
			source="solution"
		>
			<AutocompleteInput optionText="name" filterToQuery={solutionQuery} />
		</ReferenceInput>
		<TextInput
			label={translate(`resources.escalations.majorTrigger`)}
			source={`majorTrigger.${locale}`}
		/>
		<TextInput
			label={translate(`resources.escalations.filter.description`)}
			source={`description.${locale}`}
		/>
		<TextInput
			label={translate(`resources.escalations.filter.impact`)}
			source={`impact.${locale}`}
		/>

		{/*<QueryableTextInput source={`countryId`}*/}
		{/*                    label={translate(`resources.escalations.filter.description`)}/>*/}
		{/*<QueryableTextInput*/}
		{/*    source={`impact.${locale}`}*/}
		{/*    label={translate(`resources.escalations.filter.impact`)}/>*/}
		<ReferenceInput source="primaryContactId"
			reference="serviceTeams"
		>
			<AutocompleteInput optionText="it4youTeam" filterToQuery={serviceTeamQuery} />
		</ReferenceInput>
		<ReferenceArrayInput label={translate('resources.escalations.filter.businessImpactManagersIds')}
			source="businessImpactManagersIds"
			reference="businessImpactManagers"
		>
			<AutocompleteArrayInput optionText="description" filterToQuery={businessImpactManagerQuery} />
		</ReferenceArrayInput>
	</Filter>
};

// ----------------------------------------------------LIST----------------------------------------------------

export const EscalationList = props => {
	const locale = useLocale();
	const translate = useTranslate();

	return (
		<List actions={<ListActions {...props} />}
			filters={<EscalationFilter />}
			{...props}>
			<Datagrid rowClick="edit">
				<ReferenceArrayField
					reference="divisions"
					sortBy="divisions.name"
					source="divisionsIds">
					<SingleFieldList linkType={false}>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField
					reference="countries"
					sortBy="countries.nameEn"
					source="countriesIds">
					<SingleFieldList linkType={false}>
						<ChipField source="nameEn" />
					</SingleFieldList>
					<AllCountriesSelectedMessage esca={props} />
				</ReferenceArrayField>
				<ReferenceField
					source="categoryId"
					link={false}
					sortBy="category.nameEn"
					reference="categories">
					<TextField source="nameEn" />
				</ReferenceField>
				<ReferenceField
					source={`solution`}
					sortBy="solutionObject.name"
					reference={"solutionMetadatas"}
					link={false}>
					<TextField source="name" />
				</ReferenceField>
				<TextField
					source={`description.${locale}`}
					sortBy={`description.${locale}`}
					label={translate('resources.escalations.descriptionShort')} />
				<ReferenceField
					source="primaryContactId"
					link={false}
					sortBy="primaryContact.it4youTeam"
					reference="serviceTeams">
					<TextField source="it4youTeam" />
				</ReferenceField>
				<ReferenceArrayField
					source="businessImpactManagersIds"
					sortBy="businessImpactManagers.description"
					reference="businessImpactManagers">
					<SingleFieldList linkType={false}>
						<ChipField source="description" />
					</SingleFieldList>
				</ReferenceArrayField>
				<BooleanField
					source="isDraft"
					sortBy="draftId"
					label={translate('resources.escalations.isDraft')} />
				<CloneButton />
			</Datagrid>
		</List>
	)
};

// ----------------------------------------------------Detailed NEW------------------------------------------

const GetSteps = () => {
	const translate = useTranslate();

	return [
		translate('resources.escalations.steps.countryDivisions'),
		translate('resources.escalations.steps.processSystemApplication'),
		translate('resources.escalations.steps.description'),
		translate('resources.escalations.steps.impact'),
		translate('resources.escalations.steps.businessCriticalTime'),
		translate('resources.escalations.steps.contacts'),
		translate('resources.escalations.steps.linkResources'),
		translate('resources.escalations.steps.mailTemplate'),
		translate('resources.escalations.steps.sMSTemplate')
	];
}

const StepActionsButtons = ({ activeStep, setActiveStep, isEditMode, canGoNextValidators }) => {
	const classes = useStyles();
	const steps = GetSteps();
	const translate = useTranslate();

	function handleNext() {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	}

	function handleBack() {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	}

	return (
		<div>
			<Button
				disabled={activeStep === 0}
				onClick={handleBack}
				className={classes.button}
			>
				Back
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleNext}
				className={classes.button}
				disabled={isEditMode ? false : !canGoNextValidators.every(validator => {
					return validator && validator.length;
				})}
			>
				{activeStep === steps.length - 1 ? translate('finish') : translate('next')}
			</Button>
		</div>
	)
}

const EscalationToolbar = props => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();
	const { permissions: ccAdmin } = usePermissions();

	function handleReset() {
		props.setActiveStep(0);
	}

	async function handleApprove(escalationId, values) {
		const filter = JSON.stringify({ filter: { escalationId, isResolved: false, declineCheck: true } });
		let unresolvedDeclines = [];

		if (escalationId) {
			const res = await fetch(config.apiUri + `/declines?query=${filter}`, {
				method: "get",
				headers: {
					"Content-Type": "application/json",
					authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
				}
			});
			unresolvedDeclines = await res.json();
		}

		if (unresolvedDeclines.length) {
			notify('escalationApproveError', { messageArgs: { declines: `${unresolvedDeclines.map(decline => ` ${decline.title}`)}` } });
			return;
		}

		const updatedEscalation = await fetch(config.apiUri + `/escalations-review/approve/${escalationId}`, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
			},
			body: JSON.stringify({
				state: States.GREEN,
				values
			})
		});

		const status = updatedEscalation.status;
		if (status === 403) {
			notify('unauthorized', { messageArgs: { status } });
			return;
		}

		if (status === 200) {
			notify('escalationApprove');
			redirect('/review-dashboard');
		}
	};

	const ApproveButton = () => {
		const translate = useTranslate();
		const { id } = useParams()
		const values = useWatch();

		return (
			<Button
				variant="outlined"
				color="success"
				onClick={() => handleApprove(id, values)}
			>
				<ThumbUpAltIcon /> {translate(`approveButton`)}
			</Button>
		)
	};

	async function handleDecline(escalationId) {
		const checkIfAbleToDecline = await fetch(config.apiUri + `/escalations-review/decline/${escalationId}`, {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
			}
		});

		const status = checkIfAbleToDecline.status;

		if (status === 403) {
			notify('unauthorized', { messageArgs: { status } });
			return;
		}

		if (status === 200) {
			redirect(`/declines/create?escalation_id=${escalationId}`);
		}
	}

	const DeclineButton = () => {
		const translate = useTranslate();
		const { id } = useParams();

		return (
			<Button
				variant="outlined"
				color="error"
				onClick={() => handleDecline(id)}
			>
				<ThumbDownAltIcon /> {translate(`declineButton`)}
			</Button>
		)
	};


	const steps = GetSteps();
	const disabled = props.activeStep !== steps.length && !props.isEditMode;

	const CustomButton = () => <Button variant="contained" onClick={handleReset}><EditIcon /> Continue Editing</Button>;

	return <Toolbar style={{ justifyContent: "space-between", display: disabled ? "none" : "" }}>
		<SaveButton
			label={translate('save')}
			disabled={props.invalid}
		// onSuccess={(response) => {
		//     console.log(response);
		//     notify("escalationCreatedSuccess", "info");
		//     redirect('list', '/escalations');
		// }}
		// onFailure={(error) => {
		//     notify('escalationCreatedError', 'error', {error: error.message});
		//     redirect('list', '/escalations');
		// }}
		// TODO: the above code produces the desired notification but breaks the update process. Look at this again
		// after upgrading react-admin to v4 maybe
		/>

		{props.isEditMode && <ApproveButton />}
		{props.isEditMode && <DeclineButton />}
		{!props.isEditMode && <CustomButton />}
		{ccAdmin === "true" && <DeleteButton record={props.record} variant="outlined" color="inherit" />}
		{/*{props.isEditMode && (*/}
		{/*  <DeleteButton/>*/}
		{/*)}*/}
	</Toolbar>
};

const GetStepContent = ({ step, classes, activeStep, setActiveStep, isEditMode, translationLanguage, setTranslationLanguage }) => {
	const values = useWatch();
	const translate = useTranslate();
	const locale = useLocale();
	const record = useRecordContext();
	const translationLangs = languageCodes.map(code => ({ code, 'name': translate(`languages.${code}`) }));
	const { permissions: ccAdmin } = usePermissions();
	const validateProp = (prop) => {
		if (!isEditMode) {
			return values?.[prop]?.en;
		} else {
			return values?.[prop]?.[locale];
		}
	}
	const { setValue } = useFormContext();

	const handleBlur = (e) => {
		setValue(e.target.name, prependHttps(e.target.value), { shouldDirty: true });
	}
	const businessCriticalTimesValidator = [];

	if (step === 4) {
		if (values.businessCriticalTimes && values.businessCriticalTimes.length) {
			values.businessCriticalTime = values.businessCriticalTimes[0];
		}

		let valid = false;
		if (values.businessCriticalTimes?.length) {
			const group = values.businessCriticalTimes[0];
			if (group?.time?.from && group?.time?.to && group?.date?.from && group.date?.to) {
				valid = true;
			}
		}
		if (!valid) businessCriticalTimesValidator.push(false);
	}

	switch (step) {
		case 0:
			return (
				<div className="container-fluid">

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>
						{record?.isAllCountries
							? <div style={{ display: "inline-block" }}>
								<span>{translate('resources.escalations.countrySelection')}</span>
								<span style={{ fontWeight: "bold" }}>{(" - All countries affected!")}</span>
							</div>
							: translate('resources.escalations.countrySelection')
						}
					</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								reference="countries"
								source="countriesIds"
								validate={required()}
								fullWidth>
								<AutocompleteArrayInput optionText="nameEn" filterToQuery={nameEnQuery} />
							</ReferenceArrayInput>
						</div>
						{ /* Commented all + buttons because not implemented yet */}
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Country"
								reference="countries"
								source="countryId"
								content={CreateEditFormCountry} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.divisionsSelection')}</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="Divisions"
								reference="divisions"
								source="divisionsIds"

								validate={required()}
								fullWidth>
								<AutocompleteArrayInput optionText="name" filterToQuery={divisionQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Division"
								reference="divisions"
								source="divisionsIds"
								isarrayinput="true"
								content={CreateEditFormDivision} />
						} */}
					</div>

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[values.countriesIds, values.divisionsIds]} />
					}
				</div>
			);
		case 1:
			return (
				<div className="container-fluid">
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.processSystemApplication')}</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceInput className="col-12"
								source="categoryId"
								reference="categories"
								label="process / system / application"
							>
								<AutocompleteInput optionText="nameEn" filterToQuery={categoryQuery} validate={required()} fullWidth />
							</ReferenceInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="process / system / application"
								reference="categories"
								source="categoryId"
								content={CreateEditFormCategory} />
						} */}
					</div>
					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[values.categoryId]} />
					}
				</div>
			)
		case 2:
			return (
				<div>
					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.language')}</Typography>
							<Select value={translationLanguage} onChange={(e) => setTranslationLanguage(e.target.value)}>
								{translationLangs.map(lang =>
									<MenuItem key={lang.code} value={lang.code}>
										{lang.name}
									</MenuItem>)}
							</Select>
						</>}

					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.majorTrigger')}</Typography>
							<TextInput source={`majorTrigger.${translationLanguage}`}
								label=""
								multiline
								fullWidth />
						</>
					}

					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.englishMajorTrigger')}</Typography>
							<TextInput source={`majorTrigger.en`}
								label=""
								multiline
								fullWidth />
						</>
					}

					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.description')}</Typography>
							<TextInput source={`description.${translationLanguage}`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}

					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.englishDescriptionEscalation')}</Typography>
							<TextInput source={`description.en`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}> {translate('resources.escalations.solution')}  </Typography>
					<ReferenceInput source="solution"
						reference="solutionMetadatas"
						label="solution-/application name"
					>
						<AutocompleteInput
							isOptionEqualToValue={(option, value) => option._id === value._id}
							optionText="name"
							filterToQuery={solutionQuery}
							validate={required()}
							fullWidth
						/>
					</ReferenceInput>
					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[values.solution, validateProp('description')]} />
					}

				</div>
			)
		case 3:
			return (
				<div>
					{isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.impact')}</Typography>
							<TextInput source={`impact.${translationLanguage}`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}
					{!isEditMode &&
						<>
							<Typography variant="subtitle1"
								style={{ fontWeight: 600 }}>{translate('resources.escalations.impactEn')}</Typography>
							<TextInput source={`impact.en`}
								label=""
								multiline
								validate={required()}
								fullWidth />
						</>
					}
					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[validateProp('impact')]} />
					}
				</div>
			)
		case 4:
			return (
				<div>
					<ArrayInput label="" source="businessCriticalTimes">
						<SimpleFormIterator>
							<p>{translate('resources.escalations.businessCriticalTime')}</p>
							<TextInput label="From (Time)"
								source="time.from"
								type="time"
								validate={required()}
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							<TextInput label="To (Time)"
								source="time.to"
								type="time"
								validate={required()}
								formClassName={classes.inlineBlock}
								style={{ width: '12rem' }} />

							<p>{translate('resources.escalations.businessCriticalDays')}</p>
							<SelectInput
								label="From (Date)"
								source="date.from"
								validate={required()}
								formClassName={classes.inlineBlock}
								choices={days}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							<SelectInput
								label="To (Date)"
								source="date.to"
								validate={required()}
								formClassName={classes.inlineBlock}
								choices={days}
								style={{ width: '12rem' }} />
						</SimpleFormIterator>
					</ArrayInput>

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={businessCriticalTimesValidator} />
					}
				</div>
			)
		case 5:
			return (
				<div className="container-fluid">

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.primaryContactId')}</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceInput className="col-12"
								source="primaryContactId"
								reference="serviceTeams"
								label="The primary responsible contact"
							>
								<AutocompleteInput
									isOptionEqualToValue={(option, value) => option._id === value._id}
									optionText="it4youTeam"
									filterToQuery={serviceTeamQuery}
									validate={required()}
									fullWidth />
							</ReferenceInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Primary Contact"
								reference="serviceTeams"
								source="primaryContactId"
								content={ServiceTeamForm} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.secondaryContactsIds')}</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="The secondary responsible contacts"
								reference="serviceTeams"
								source="secondaryContactsIds"

								fullWidth>
								<AutocompleteArrayInput optionText="it4youTeam" filterToQuery={serviceTeamQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Secondary Contact"
								reference="serviceTeams"
								source="secondaryContactsIds"
								isarrayinput="true"
								content={ServiceTeamForm} />
						} */}
					</div>

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.businessImpactManagersIds')}</Typography>
					<div className="row align-items-start">
						<div className="col-10">
							<ReferenceArrayInput className="col-12"
								label="Business Impact Managers"
								source="businessImpactManagersIds"
								reference="businessImpactManagers"

								fullWidth>
								<AutocompleteArrayInput optionText="description" filterToQuery={businessImpactManagerQuery} />
							</ReferenceArrayInput>
						</div>
						{/* {ccAdmin === "true" &&
							<Popup className="col-2"
								title="Business Impact Manager"
								reference="businessImpactManagers"
								source="businessImpactManagersIds"
								isarrayinput="true"
								content={BusinessImpactManagerForm} />
						} */}
					</div>
					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[
								values.primaryContactId,
							]} />
					}
				</div>
			);
		case 6:
			return (
				<div>
					<ArrayInput source="linkResources">
						<SimpleFormIterator>
							<TextInput label=""
								source="url"
								type="url"
								placeholder='https://mydomain.com'
								onBlur={handleBlur}
								validate={required()}
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
							<TextInput label=""
								source="name"
								placeholder="name"
								formClassName={classes.inlineBlock}
								style={{ "marginRight": "2rem", width: '12rem' }} />
						</SimpleFormIterator>
					</ArrayInput>

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[]} />
					}
				</div>
			);
		case 7:
			return (
				<div>
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.mailSubjectDe')}</Typography>
					<TextInput source="mailSubjectDe"
						label=""
						fullWidth />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.mailSubjectEn')}</Typography>
					<TextInput source="mailSubjectEn"
						label=""
						fullWidth />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.mailTextDe')}</Typography>
					<TextInput source="mailTextDe"
						label=""
						fullWidth
						multiline />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.mailTextEn')}</Typography>
					<TextInput source="mailTextEn"
						label=""
						fullWidth
						multiline />

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[]} />
					}
				</div>

			);
		case 8:
			return (
				<div>
					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.smsTextDe')}</Typography>
					<TextInput source="smsTextDe"
						label=""
						fullWidth
						multiline />

					<Typography variant="subtitle1"
						style={{ fontWeight: 600 }}>{translate('resources.escalations.smsTextEn')}</Typography>
					<TextInput source="smsTextEn"
						label=""
						fullWidth
						multiline />

					{!isEditMode &&
						<StepActionsButtons activeStep={activeStep} setActiveStep={setActiveStep}
							isEditMode={isEditMode}
							canGoNextValidators={[]} />
					}
				</div>
			);
		default:
			return 'Unknown step';
	}
}

export const VerticalStepperForm = props => {
	const classes = useStyles();
	const activeStep = props.activeStep;
	const setActiveStep = props.setActiveStep;
	const steps = GetSteps();
	const translate = useTranslate();
	const locale = useLocale();
	const [translationLanguage, setTranslationLanguage] = useState(locale);

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep}
				orientation="vertical">
				{steps.map((label, index) => (
					<Step key={label} {...props.isEditMode ? { active: true } : {}}>
						<StepButton onClick={handleStep(index)}>
							{label}
						</StepButton>
						<StepContent>
							<GetStepContent step={index} classes={classes} activeStep={activeStep}
								setActiveStep={setActiveStep} isEditMode={props.isEditMode} translationLanguage={translationLanguage} setTranslationLanguage={setTranslationLanguage} />
						</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square
					elevation={0}
					className={classes.resetContainer}>
					<Typography
						style={{ textAlign: "center" }}>{translate('resources.escalations.confirmMsg')} </Typography>
					{/*<EscalationToolbar handleReset={handleReset}/>*/}
				</Paper>
			)}
		</div>
	);
}

// ----------------------------------------------------CREATE------------------------------------------------

export const EscalationCreate = props => {
	const [activeStep, setActiveStep] = React.useState(0);
	const editMode = false; // Because of cloning
	const translate = useTranslate();

	return (
		<Create redirect="list" title={<Title prefix={translate('create')} resource="Escalation" select={escalation} />} {...props}>
			<SimpleForm toolbar={<EscalationToolbar activeStep={activeStep} setActiveStep={setActiveStep}
				isEditMode={editMode} />}>
				<VerticalStepperForm redirect="list" props isEditMode={editMode} activeStep={activeStep}
					setActiveStep={setActiveStep} />
			</SimpleForm>
		</Create>
	);
};

// -------------------------------------------------- -EDIT----------------------------------------------------
const PostEditActionsInEscalationView = ({ basePath, data, resource }) => {
	const translate = useTranslate();
	return <TopToolbar> <ShowButton
		record={data}
		label={translate('showHistory')} /> </TopToolbar>;
}

export const EscalationEdit = props => {
	const { id } = useParams();
	const [activeStep, setActiveStep] = React.useState(0);
	const editMode = true;
	const translate = useTranslate();
	const record = useGetOne("escalations", { id });

	return (
		<Edit redirect="list" actions={<PostEditActionsInEscalationView />}
			title={<Title prefix={translate('edit')} resource="Escalation" select={escalation} />} {...props}>
			<SimpleForm toolbar={<EscalationToolbar activeStep={activeStep} setActiveStep={setActiveStep}
				isEditMode={editMode} />}>

				<>
					{
						record?.data?.draftId ?
							<p>
								This escalation has pending changes that are not visible on this page.
								In order to protect integrity, this escalation can not be directly edited. If you want
								to make a change, please <a href={`/#/escalationDrafts/${record?.data?.draftId}`}>view the draft</a>.
								There you'll be able to make the changes you need to make and get them approved afterwards.
							</p> : ""
					}
					<VerticalStepperForm redirect="list" props isEditMode={editMode} activeStep={activeStep}
						setActiveStep={setActiveStep} />
				</>
			</SimpleForm>
		</Edit>
	);
};
