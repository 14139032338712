import React, { useEffect, useState } from 'react';
import {
	List, Resource, Datagrid, SingleFieldList,
	ChipField, ReferenceField, TextField, ReferenceArrayField,
	useRecordContext, useRedirect, useTranslate, useLocale
} from 'react-admin';
import config from '../../common/config';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Card } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FindInPageIcon from '@mui/icons-material/FindInPage';

const EMAIL_REGEX = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)';

const useStyles = makeStyles((theme) => ({
	container: {
		'padding': '1rem',
	},
	title: {
		'font-size': '1.5rem',
	},
	dashboardChartReadContainer: {
		'padding': '1rem',
	},
	dashboardChartReadCardContainer: {
		'padding': '1rem',
	},
	dashboardChartReadGreenRow: {
		'color': 'green !important',
		'font-weight': 'bold'
	},
	dashboardChartReadYellowRow: {
		'color': '#fef200 !important',
		'font-weight': 'bold'
	},
	dashboardChartReadRedRow: {
		'color': 'red !important',
		'font-weight': 'bold'
	},
	dashboardChartContainer: {
		'padding': '1rem',
	},
	dashboardChartRow: {
		'display': 'flex',
		'align-items': 'center',
		'justify-content': 'flex-start',
		'width': '100%',
		'border-radius': '2px',
		'overflow': 'hidden',
		'height': '2rem',
		'box-shadow': '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	},
	dashboardChartGreenColumn: {
		'background-color': 'green',
		'height': '100%',
		'min-width': '0.1%'
	},
	dashboardChartYellowColumn: {
		'background-color': '#fef200',
		'height': '100%',
		'min-width': '0.1%'
	},
	dashboardChartRedColumn: {
		'background-color': 'red',
		'height': '100%',
		'min-width': '0.1%'
	},
	accordionContainer: {
		'padding': '1rem',
	},
	accordionHeading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	accordionFirstTab: {
		'width': '20px',
		'height': '20px',
		'background': 'red',
		'border-radius': '50%',
		'vertical-align': 'middle',
		'display': 'inline-block',
		'margin-right': '5px'
	},
	accordionSecondTab: {
		'width': '20px',
		'height': '20px',
		'background': '#fef200',
		'border-radius': '50%',
		'vertical-align': 'middle',
		'display': 'inline-block',
		'margin-right': '5px'
	},
	accordionThirdTab: {
		'width': '20px',
		'height': '20px',
		'background': 'green',
		'border-radius': '50%',
		'vertical-align': 'middle',
		'display': 'inline-block',
		'margin-right': '5px'
	}
}));

const isValidEmail = email => {
	const pattern = new RegExp(EMAIL_REGEX);

	return pattern.test(email);
};

const sendEmail = async escalationRecord => {
	const solutionObject = escalationRecord.solutionObject || null;
	const solutionAdmins = [
		solutionObject?.owner?.toLowerCase(),
		solutionObject?.ownerDeputy?.toLowerCase(),
		solutionObject?.expert?.toLowerCase(),
		solutionObject?.expertDeputy?.toLowerCase()
	].filter(x => x && isValidEmail(x));
	const subject = '';
	const emailBody = '';

	// email separator for Outlook is semicolon
	window.location.href = `mailto:${solutionAdmins.toString().replaceAll(',', ';')}`;

	// LEAVE IT ON CASE CLIENTS WANTS TO REACtIVATE EMAILS FROM SERVER SIDE
	// sendEscalationEmail(escalationRecord.id);
}

const openEskaDetailPage = async (redirect, escalationRecord) => {
	redirect('edit', 'escalations', escalationRecord.id);
}

const sendEscalationEmail = async (escaId) => {
	console.log('FETCH Escalations In Grace Data ....');
	const escalationEmail = await fetch(config.apiUri + "/review-dash/escalation/send-email", {
		method: "post",
		responseType: 'application/json',
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
		},
		body: JSON.stringify({
			escaId: escaId
		})
	});
	const responseEscalationEmail = await escalationEmail.json();
};

export const EscalationReviewDashboardListItemActions = props => {
	const escalationRecord = useRecordContext();
	const redirect = useRedirect();

	return (
		<div>
			<IconButton onClick={(e) => sendEmail(escalationRecord)}>
				<ForwardToInboxIcon color="action" />
			</IconButton>
			<IconButton onClick={(e) => openEskaDetailPage(redirect, escalationRecord)}>
				<FindInPageIcon color="action" />
			</IconButton>
		</div>
	);
}

export const EscalationReviewDashboardList = props => {
	const locale = useLocale();
	const translate = useTranslate();

	return (
		<List title=" " exporter={false} {...props}>
			<Datagrid bulkActionButtons={false}>
				<ReferenceField source={`solution`} reference={"solutionMetadatas"} link={false} sortBy="solutionObject.name">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceArrayField reference="divisions" source="divisionsIds" sortBy="divisions.name">
					<SingleFieldList linkType={false}>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField reference="countries" source="countriesIds" link={false} sortBy="countries.nameEn">
					<SingleFieldList linkType={false}>
						<ChipField source="nameEn" />
					</SingleFieldList>
				</ReferenceArrayField>
				<TextField source={`description.${locale}`} label={translate('resources.escalations.descriptionShort')} sortBy={`description.${locale}`} />
				<EscalationReviewDashboardListItemActions esca={props} />
			</Datagrid>
		</List>
	)
};


const ReviewDashboard = (props) => {
	const translate = useTranslate();
	const classes = useStyles();

	const [dashboardChartGreenColumnWidth, setDashboardChartGreenColumnWidth] = useState(0);
	const [dashboardChartYellowColumnWidth, setDashboardChartYellowColumnWidth] = useState(0);
	const [dashboardChartRedColumnWidth, setDashboardChartRedColumnWidth] = useState(0);

	useEffect(() => {
		async function fetchData() {
			const reviewDashboardChart = await fetch(config.apiUri + "/review-dash/chart", {
				headers: {
					authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
				}
			});

			// if response status is 401 redirect to login page
			if (reviewDashboardChart.status === 401) {
				localStorage.removeItem("oicdAuthTokenEska");
				window.location.href = "/";
			};

			const responseReviewDashboardChart = await reviewDashboardChart.json();

			setDashboardChartGreenColumnWidth(responseReviewDashboardChart.escalationsCountReviewed);
			setDashboardChartYellowColumnWidth(responseReviewDashboardChart.escalationsCountInGrace);
			setDashboardChartRedColumnWidth(responseReviewDashboardChart.escalationsCountOuOfGrace);
		}
		fetchData();
	}, []);

	const [expanded, setExpanded] = React.useState(true);

	const handleChange = (panel) => (event, isExpanded) => {
		// setExpanded(isExpanded ? panel : false);

		if (isExpanded) {
			setExpanded(panel);
		} else {
			setExpanded(false);
			// TODO: Maybe we would need to reset some variables when tab is hidden...
		}
	};

	return (
		<div className={classes.container}>
			<h1 className={classes.title}>{translate("reviewDashboard.pageTitle")}</h1>

			<div className={classes.dashboardChartReadContainer}>
				<Card className={classes.dashboardChartReadCardContainer}>
					<div className={classes.dashboardChartReadGreenRow}>
						{dashboardChartGreenColumnWidth}% {translate("reviewDashboard.dashboardChartGreenColumnText")}
					</div>
					<div className={classes.dashboardChartReadYellowRow}>
						{dashboardChartYellowColumnWidth}% {translate("reviewDashboard.dashboardChartYellowColumnText")}
					</div>
					<div className={classes.dashboardChartReadRedRow}>
						{dashboardChartRedColumnWidth}% {translate("reviewDashboard.dashboardChartRedColumnText")}
					</div>
				</Card>
			</div>

			<div className={classes.dashboardChartContainer}>
				<div className={classes.dashboardChartRow}>
					<Tooltip title={translate("reviewDashboard.dashboardChartGreenColumnText")} aria-label="add">
						<div style={{ width: `${dashboardChartGreenColumnWidth}%` }} className={classes.dashboardChartGreenColumn}> </div>
					</Tooltip>
					<Tooltip title={translate("reviewDashboard.dashboardChartYellowColumnText")} aria-label="add">
						<div style={{ width: `${dashboardChartYellowColumnWidth}%` }} className={classes.dashboardChartYellowColumn}> </div>
					</Tooltip>
					<Tooltip title={translate("reviewDashboard.dashboardChartRedColumnText")} aria-label="add">
						<div style={{ width: `${dashboardChartRedColumnWidth}%` }} className={classes.dashboardChartRedColumn}> </div>
					</Tooltip>
				</div>
			</div>

			<div className={classes.accordionContainer}>
				<Accordion onChange={handleChange('escalationsOutOfGrace')} TransitionProps={{ unmountOnExit: true }} expanded={expanded === "escalationsOutOfGrace"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.accordionHeading}><span className={classes.accordionFirstTab}></span>{translate("reviewDashboard.accordionFirstTabTitle")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Resource name="review-dash/escalations/out-of-grace" list={EscalationReviewDashboardList} />
					</AccordionDetails>
				</Accordion>
				<Accordion onChange={handleChange('escalationsInGrace')} TransitionProps={{ unmountOnExit: true }} expanded={expanded === "escalationsInGrace"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography className={classes.accordionHeading}><span className={classes.accordionSecondTab}></span>{translate("reviewDashboard.accordionSecondTabTitle")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Resource name="review-dash/escalations/in-grace" list={EscalationReviewDashboardList} />
					</AccordionDetails>
				</Accordion>
				<Accordion onChange={handleChange('escalationsReviewed')} TransitionProps={{ unmountOnExit: true }} expanded={expanded === "escalationsReviewed"}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography className={classes.accordionHeading}><span className={classes.accordionThirdTab}></span>{translate("reviewDashboard.accordionThirdTabTitle")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Resource name="review-dash/escalations/reviewed" list={EscalationReviewDashboardList} />
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
};

export default ReviewDashboard;
