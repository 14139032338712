import React from 'react';
import {
	Create, Edit, Filter,
	List, Datagrid, TopToolbar, SimpleForm,
	required,
	ShowButton,
	TextField,
	TextInput,
	usePermissions, useTranslate
} from 'react-admin';
import { QueryableTextInput, Title } from '../common'
import { ListActions } from './utils/pdfDownload';

const PostEditActions = ({ basePath, data, resource }) => <TopToolbar> <ShowButton
	record={data}
	label={'Show History'} /> </TopToolbar>;

const CategoryFilter = props => {
	const translate = useTranslate();

	return <Filter {...props}>
		<QueryableTextInput source="nameEn"
			label={translate('resources.categories.filter.nameEn')} />
		<QueryableTextInput source="nameDe"
			label={translate('resources.categories.filter.nameDe')} />
	</Filter>
};

export const CategoryList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List filters={<CategoryFilter />}
			actions={<ListActions {...props} />}
			{...props}>
			<Datagrid rowClick="edit">
				<TextField source="nameEn" />
				<TextField source="nameDe" />
			</Datagrid>
		</List>
	)
};

const name = x => x.nameEn

export const CategoryEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return <Edit actions={<PostEditActions />}
		title={<Title prefix={translate('edit')}
			resource="Category"
			select={name} />} {...props}>
		<CreateEditFormCategory />
	</Edit>
};

export const CategoryCreate = props => {
	const translate = useTranslate();

	return <Create redirect="list" title={<Title prefix={translate('create')}
		resource="Category"
		select={name} />} {...props}>
		<CreateEditFormCategory />
	</Create>
};

export const CreateEditFormCategory = props => { // todo: check! how it behaviors in escalation import
	return <SimpleForm {...props}>
		<TextInput source="nameEn"
			validate={required()} />
		<TextInput source="nameDe" />
	</SimpleForm>
};
