const {
    REACT_APP_OIDC_ISSUER,
    REACT_APP_OIDC_METADATA_ENDPOINT,
    REACT_APP_OIDC_CLIENT_ID,
    REACT_APP_OIDC_REDIRECT_URI,
    REACT_APP_OIDC_AUTH_ENDPOINT,
    REACT_APP_API_URI
}  = process.env

const config = {
  issuer: REACT_APP_OIDC_ISSUER || "https://federation-q.auth.schwarz/nidp/oauth/nam",
  authEndpoint: REACT_APP_OIDC_AUTH_ENDPOINT || "https://federation-q.auth.schwarz/nidp/oauth/nam/authz",
  metadataEndpoint: REACT_APP_OIDC_METADATA_ENDPOINT || "https://federation-q.auth.schwarz/nidp/oauth/nam/.well-known/openid-configuration",
  clientId: REACT_APP_OIDC_CLIENT_ID || "5af7157e-0202-42ff-88cf-04ff179d1240",
  redirectUri: REACT_APP_OIDC_REDIRECT_URI || "https://localhost:3000",
  apiUri: REACT_APP_API_URI || "http://localhost:3001",
}
export default config;
