import React from 'react';
import {
	List, Datagrid, TopToolbar,
	TextField, ReferenceField,
	ReferenceInput, SelectInput, AutocompleteInput, TextInput,
	TabbedForm, FormTab,
	Edit, Create, Filter,
	required,
	ShowButton,
	usePermissions, useTranslate
} from 'react-admin';
import {
	Title, QueryableTextInput,
} from '../common'
import { query } from "../../common/functions"
import { ListActions } from './utils/pdfDownload';


const countryQuery = query("nameEn")
const divisionQuery = query("name")
export const telephoneNumberTypes = ["Landline", "Mobile"].map(x => ({ id: x, name: x }))

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();
	return <TopToolbar> <ShowButton record={data} label={translate('showHistory')} /> </TopToolbar>;
}

const BusinessImpactManagerFilter = props => {
	const translate = useTranslate();

	return <Filter {...props}>
		<QueryableTextInput source="description" label={translate('resources.businessImpactManagers.filter.description')} />
		<ReferenceInput source="countryId" reference="countries" >
			<AutocompleteInput optionText="nameEn" filterToQuery={countryQuery} />
		</ReferenceInput>
		<ReferenceInput source="divisionId" reference="divisions" >
			<AutocompleteInput optionText="name" filterToQuery={divisionQuery} />
		</ReferenceInput>
		<QueryableTextInput source="phoneNumber" label={translate('resources.businessImpactManagers.filter.phoneNumber')} />
		<SelectInput source="phoneNumberType" label={translate('resources.businessImpactManagers.filter.phoneNumberType')} choices={telephoneNumberTypes} />
	</Filter>
};

export const BusinessImpactManagerList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List filters={<BusinessImpactManagerFilter />}
			actions={<ListActions />}
			{...props}>
			<Datagrid rowClick="edit">
				<TextField source="description" />
				<ReferenceField source="countryId" reference="countries">
					<TextField source="nameEn" />
				</ReferenceField>
				<TextField source="phoneNumber" />
				<TextField source="phoneNumberType" />
				<ReferenceField source="divisionId" reference="divisions">
					<TextField source="name" />
				</ReferenceField>
			</Datagrid>
		</List>
	)
};

export const BusinessImpactManagerForm = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return <TabbedForm {...props}>
		<FormTab label={translate('resources.businessImpactManagers.general')}>
			<TextInput source="description" validate={required()} />
			<ReferenceInput source="countryId" reference="countries"  >
				<AutocompleteInput
					optionText="nameEn"
					filterToQuery={countryQuery}
					validate={required()}
					isOptionEqualToValue={(option, value) => option._id === value._id}
				/>
			</ReferenceInput>
			<ReferenceInput source="divisionId" reference="divisions"  >
				<AutocompleteInput optionText="name" filterToQuery={divisionQuery} validate={required()} />
			</ReferenceInput>
		</FormTab>
		<FormTab label={translate('resources.businessImpactManagers.phone')} {...props}>
			<TextInput source="phoneNumber" label="number" validate={required()} />
			<SelectInput source="phoneNumberType" label="type" choices={telephoneNumberTypes} validate={required()} />
			<TextInput source="phoneNumberRemarkEn" label="remarkEn" />
			<TextInput source="phoneNumberRemarkDe" label="remarkDe" />
		</FormTab>
	</TabbedForm>
}

const description = x => x.description

export const BusinessImpactManagerEdit = props => {
	const translate = useTranslate();
	return (<Edit actions={<PostEditActions />} title={<Title prefix={translate('edit')} resource="BusinessImpactManager" select={description} />} {...props}>
		<BusinessImpactManagerForm />
	</Edit>
	)
};

export const BusinessImpactManagerCreate = props => {
	const translate = useTranslate();
	return (<Create redirect="list" title={<Title prefix={translate('create')} resource="BusinessImpactManager" select={description} />} {...props}>
		<BusinessImpactManagerForm />
	</Create>
	)
};
