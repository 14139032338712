export const like = (term, caseInsensitive = true) => ({ $regex: `${term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}.*`, ...(caseInsensitive && {$options: "i"} || {}) })

export const unlike = ({ $regex: x } = {}) => x && x.substring(0, x.length - 2) || ''

export const query = field => term => ({ [field]: like(term)})

export const getEnv = (name, defaultValue) => process.env[name] || defaultValue

const tokenKey = 'oicdAuthTokenEska'

export const getToken = () => localStorage.getItem(tokenKey)

export const setToken = token => {
    localStorage.setItem(tokenKey, token)
}

export const removeToken = () => localStorage.removeItem(tokenKey)

export const prependHttps = (value) => {
    if(value.search(/[a-z]+:\/\//) === 0) {
        return value;
    } else {
        return `https://${value}`;
    }
};
