import React, {useEffect, useState} from 'react';
import {Title, useNotify, useRedirect, useTranslate} from "react-admin";
import {Typography, CardContent, Card, Checkbox, FormControlLabel} from "@mui/material";
import Config from "../common/config";
import Button from "@mui/material/Button";
import {removeToken} from "../common/functions";

const EmailSettings = () => {
    const translate = useTranslate();
    const [checkboxes, setCheckboxes] = useState({});
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        async function fetchData(){
            const res = await fetch(Config.apiUri + "/email-preferences", {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
                }
            });
            const body = await res.json();
            const {_id, username, email, ...checkboxValues} = body;
            setCheckboxes(checkboxValues);
            // TODO if 401, redirect to login
        }
        fetchData();
    }, []);

    return (
        <Card>
            <Title title="My Page"/>
            <CardContent>
                <Typography variant="h4">{translate("emailSettings.heading")}</Typography>
                <Typography variant="body1">
                    {translate("emailSettings.explanation")}
                </Typography>

                {Object.keys(checkboxes).map(name => (
                    <div key={name}>
                        <FormControlLabel control={
                            <Checkbox checked={checkboxes[name]}
                                      onChange={(e) => {
                                          setCheckboxes({
                                              ...checkboxes,
                                              [name]: e.target.checked
                                          })
                                      }}/>
                        } label={translate("emailSettings." + name)}/>
                    </div>
                ))}
                <Button variant="contained" color="primary" onClick={async () => {
                    setLoading(true);
                    const res = await fetch(Config.apiUri + "/email-preferences", {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
                        },
                        body: JSON.stringify(checkboxes)
                    });
                    if (res.status === 401) {
                        removeToken();
                        redirect("/");
                    } else {
                        setLoading(false);
                        notify("Saved", {type: "success"});
                    }
                }} disabled={loading}>{translate("emailSettings.save")}</Button>
            </CardContent>
        </Card>
    );
};

export default EmailSettings;
