import React, { useCallback, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import settings from '../common/config'
import { setToken } from '../common/functions'
import CircularProgress from "@mui/material/CircularProgress";
import {useLogin, useTranslate} from 'react-admin';
import { jwtDecode } from 'jwt-decode';

const cleanup = () => {
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundImage: 'url(https://source.unsplash.com/featured/?{nature})',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  containerbg: {
    background: 'rgba(173,216,230,0.8)',
    borderRadius: '10px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon: {
    marginRight: theme.unit,
  },
}));

const LoginForm = () => {
  const classStyles = useStyles();
  const [loading, setLoading] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const translate = useTranslate();
  const login = useLogin();

  const doLogin = useCallback(async (code, state) => {
    const stateKey = `oidc.${state}`;
    const { code_verifier } = JSON.parse(localStorage.getItem(stateKey) || '{}');

    if(code && !code_verifier){
      setTimeout(()=>{
        login({ completeLogin: false }); // retrigger auth flow
      }, 800);
      return;
    }

    const response = await fetch(`${settings.apiUri}/code-to-token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ client_id: settings.clientId, redirect_uri: window.location.origin, code: code, code_verifier }),
    });

    if (!response.ok) {
      if ((await response.text()) === 'The requester is not allowed to use the application') {
        console.log('The requester is not allowed to use the application!!');
        setUnauthorized(true);
      } else {
        setLoginFailed(true);
      }
      console.error('LOGIN FAILED');
      setLoading(false);
      return;
    }

    let token;
		let user;
    try {
      const responseBody = JSON.parse(await response.text());
			const decodedIdToken = jwtDecode(responseBody.id_token);
			user = {
				firstName: decodedIdToken.givenName,
				lastName: decodedIdToken.sn,
        email: decodedIdToken.mail
			}
      token = responseBody.access_token;
    } catch (e) {
      console.error('Error while processing access_token');
      return;
    }

    cleanup();
    setToken(token);
		localStorage.setItem("user", JSON.stringify(user))
    login({ completeLogin: true });
  }, [login])

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    // If code & state is present, we came back from the provider
    if (code && state) {
      setLoading(true);
      doLogin(code, state);
    }
  }, [login, doLogin]);

  const handleLogin = () => {
    setLoading(true);
    login(); // Do not provide code, just trigger the redirection
  };

  return (
    <Grid container component="main" className={classStyles.root}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classStyles.containerbg}>
          <div className={classStyles.paper}>
            <Avatar className={classStyles.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {translate('login.signInTitle')}
            </Typography>

            {loading && (
              <CircularProgress
                className={classStyles.icon}
                size={18}
                thickness={2}
              />
            )}

            <form className={classStyles.form} noValidate>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classStyles.submit}
                onClick={handleLogin}
              >
                {translate('login.signInBtn')}
              </Button>

            </form>
          </div>
        </div>
        <Box mt={4} className={classStyles.containerbg}>
          {
            unauthorized &&
            <Typography variant="body2" color="error" align="center">
              <b>{translate('login.errorRights')} <Link href="https://siam.schwarz/roles/9000069032" target="_blank" ariant="body2" style={{ fontWeight: "bold" }}> {"here"}</Link></b>.
            </Typography>
          }
        </Box>
        <Box mt={4} className={classStyles.containerbg}>
          {
            loginFailed &&
            <Typography variant="body2" color="error" align="center">
              <b>{translate('login.errorLogin')}</b>.
            </Typography>
          }
        </Box>
      </Container>
    </Grid>
  );
}

// const mapDispatchToProps = {
//   login: loginAction,
// }
//
// export default connect(undefined, mapDispatchToProps)(withStyles(useStyles)(LoginForm));
export default LoginForm;
