export const languageCodes = [
    'de',
/*
    'bg',
    'cs',
    'da',
    'de_AT',
    'de_CH',
    'de_LU',
    'el',
    'el_CY',
*/
    'en',
/*
    'es',
    'et',
    'fi',
    'fr',
    'fr_BE',
    'fr_CH',
    'fr_LU',
    'hr',
    'hu',
    'it',
    'it_CH',
    'lt',
    'lv',
    'mt',
    'nl',
    'nl_BE',
    'pl',
    'pt',
    'ro',
    'sk',
    'sl',
    'sr',
    'sv',
    'tr',
*/
]