import React from 'react';
import {
	Create, Edit, Filter,
	List, SingleFieldList, Datagrid, FormTab, SimpleFormIterator, TabbedForm, TopToolbar,
	ReferenceField, ArrayField, TextField,
	ArrayInput, AutocompleteArrayInput, AutocompleteInput, BooleanInput, ReferenceInput, ReferenceArrayInput, TextInput, SelectInput,
	required,
	ShowButton,
	useRecordContext, usePermissions, useTranslate
} from 'react-admin';
import Chip from '@mui/material/Chip';
import { QueryableTextInput, Title, } from '../common';
import { query } from "../../common/functions";
import makeStyles from '@mui/styles/makeStyles';
import { ListActions } from './utils/pdfDownload';

const useStyles = makeStyles((theme) => ({
	inlineBlock: { display: 'inline-flex', marginRight: '2rem' },
	firstTextMargin: { marginBottom: '-1rem', marginTop: '0.8rem' },
	textMargin: { marginBottom: '-1rem', marginTop: '-0.8rem' }
}));

const categoryQuery = query("name");
const teamAvailabiltyQuery = query("descriptionEn");
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(x => ({ id: x, name: x }));

function ChipFieldFromTo() {
	const record = useRecordContext();
	return (
		<Chip label={!record || !record.date || !record.time ? 'No availability found' : `${record.date.from} - ${record.date.to} | ${record.time.from} - ${record.time.to}`} />
	);
}

const PostEditActions = ({ basePath, data, resource }) => {
	const translate = useTranslate();

	return <TopToolbar> <ShowButton
		record={data}
		label={translate('showHistory')} /> </TopToolbar>
};

const ServiceTeamFilter = props => {
	const translate = useTranslate();

	return <Filter {...props}>
		<QueryableTextInput source="it4youId"
			label={translate('resources.serviceTeams.filter.it4youId')} />
		<QueryableTextInput source="it4youTeam"
			label={translate('resources.serviceTeams.filter.it4youTeam')} />
		<QueryableTextInput source="remarkEn"
			label={translate('resources.serviceTeams.filter.remarkEn')} />
		<QueryableTextInput source="remarkDe"
			label={translate('resources.serviceTeams.filter.remarkDe')} />
		<ReferenceInput source="categoryId"
			reference="teamCategories"
		>
			<AutocompleteInput optionText="name" filterToQuery={categoryQuery} />
		</ReferenceInput>
		<ReferenceArrayInput label={translate('resources.serviceTeams.filter.availabilities')}
			reference="teamAvailabilities"
			source="availabilitiesIds"
		>
			<AutocompleteArrayInput optionText="descriptionEn" filterToQuery={teamAvailabiltyQuery} />
		</ReferenceArrayInput>
	</Filter>
};

export const ServiceTeamList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;
	return (
		<List filters={<ServiceTeamFilter />}
			actions={<ListActions {...props} />}
			{...props}>
			<Datagrid rowClick="edit">
				<TextField source="it4youId" />
				<TextField source="it4youTeam" />
				<ReferenceField source="categoryId"
					reference="teamCategories">
					<TextField source="name" />
				</ReferenceField>
				<ArrayField source="availabilities">
					<SingleFieldList>
						<ChipFieldFromTo />
					</SingleFieldList>
				</ArrayField>
			</Datagrid>
		</List>
	)
};

const team = x => x.it4youTeam;

export const ServiceTeamForm = props => {
	const classes = useStyles();
	const translate = useTranslate();

	return <TabbedForm {...props}>
		<FormTab label={translate('resources.serviceTeams.form.tabGeneral')}>
			<TextInput source="it4youId"
				label={translate('resources.serviceTeams.fields.it4youId')}
				validate={required()} />
			<TextInput source="it4youTeam"
				label={translate('resources.serviceTeams.fields.it4youTeam')}
				validate={required()} />
			<TextInput source="remarkEn"
				label={translate('resources.serviceTeams.fields.remarkEn')}
			/>
			<TextInput source="remarkDe"
				label={translate('resources.serviceTeams.fields.remarkDe')}
			/>
			<ReferenceInput source="categoryId"
				reference="teamCategories"
				label={translate('resources.serviceTeams.fields.categoryId')}
			>
				<AutocompleteInput
					optionText="name"
					filterToQuery={categoryQuery}
					validate={required()}
					isOptionEqualToValue={(option, value) => option._id === value._id}
				/>
			</ReferenceInput>
		</FormTab>
		<FormTab label={translate('resources.serviceTeams.form.tabAvailabilities')}>
			<ArrayInput source="availabilities">
				<SimpleFormIterator>
					<p>{translate('resources.serviceTeams.form.clockTime')}</p>
					<TextInput label={translate('resources.serviceTeams.form.fromTime')}
						source="time.from"
						type="time"
						validate={required()}
						formClassName={classes.inlineBlock} />
					<TextInput label={translate('resources.serviceTeams.form.toTime')}
						source="time.to"
						type="time"
						validate={required()}
						formClassName={classes.inlineBlock} />
					<p>{translate('resources.serviceTeams.form.dateTime')}</p>
					<SelectInput
						label={translate('resources.serviceTeams.form.fromDate')}
						source="date.from"
						validate={required()}
						formClassName={classes.inlineBlock}
						choices={days} />
					<SelectInput
						label={translate('resources.serviceTeams.form.toDate')}
						source="date.to"
						validate={required()}
						formClassName={classes.inlineBlock}
						choices={days} />
					<p>{translate('resources.serviceTeams.form.phone')}</p>
					<TextInput source="phoneNumber"
						placeholder="+49"
						formClassName={classes.inlineBlock}
						validate={required()} />
					<SelectInput source="phoneNumberType"
						formClassName={classes.inlineBlock}
						choices={["Landline", "Mobile"].map(x => ({ id: x, name: x }))}
						validate={required()} />
					<BooleanInput
						defaultValue={false}
						source="nationalHolidays" />
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	</TabbedForm>;
};

export const ServiceTeamEdit = props => {
	const translate = useTranslate();
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (<Edit actions={<PostEditActions />}
		title={<Title prefix={translate('edit')}
			resource="Service Team"
			select={team} />} {...props}>
		<ServiceTeamForm />
	</Edit>
	);
};

export const ServiceTeamCreate = props => {
	const translate = useTranslate();

	return (<Create redirect="list" title={<Title prefix={translate('create')}
		resource="Service Team"
		select={team} />} {...props}>
		<ServiceTeamForm />
	</Create>
	);
};
