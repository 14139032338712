import React, { cloneElement } from 'react';
import {
	TopToolbar,
	CreateButton,
	useListContext,
	useTranslate,
	useLocale,
	downloadCSV
} from 'react-admin';
import Config from '../../../common/config';
import DownloadIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import jsonExport from 'jsonexport/dist';

const momentFormat = 'DD/MM/YYYY, hh:mm:ss';

const mapCountries = (entities) => {
	return entities.map(e => ({
		nameEn: e.nameEn,
		nameDe: e.nameDe,
		abbreviationEn: e.abbreviationEn,
		abbreviationDe: e.abbreviationDe,
	}));
};

const mapDivisions = (entities) => {
	return entities.map(e => ({
		name: e.name,
		abbreviation: e.abbreviation
	}));
};

const mapBusinessImpactManagers = (entities) => {
	return entities.map(e => ({
		description: e.description,
		countryId: e.country.nameEn,
		phoneNumber: e.phoneNumber,
		phoneNumberType: e.phoneNumberType,
		divisionId: e.division.name
	}));
};

const mapCategories = (entities) => {
	return entities.map(e => ({
		nameEn: e.nameEn,
		nameDe: e.nameDe
	}));
};

const mapServiceTeams = (entities) => {
	return entities.map(e => {
		let availabilitiesString = '';
		for (const availability of e.availabilities) {
			availabilitiesString += `${availability.date.from} - ${availability.date.to} | ${availability.time.from} - ${availability.time.to} `
		}

		return {
			it4youId: e.it4youId,
			it4youTeam: e.it4youTeam,
			categoryId: e.category?.name,
			availabilities: availabilitiesString
		};
	});
};

const mapTeamCategories = (entities) => {
	return entities.map(e => ({
		name: e.name,
		timeZone: e.timeZone
	}));
};

const mapUsers = (entities) => {
	return entities.map(e => ({
		firstName: e.firstName,
		lastName: e.lastName,
		emailAddress: e.emailAddress,
		isManager: e.isManager ? 'Yes' : 'No'
	}));
};

const mapDuties = (entities) => {
	return entities.map(e => ({
		manager: e.manager?.lastName,
		from: moment(e.from).format(momentFormat),
		to: moment(e.to).format(momentFormat),
		divisionId: e.division.name
	}));
};

const mapChangeHistory = (entities) => {
	return entities.map(e => ({
		entityType: e.entityType,
		docId: e.docId,
		changedFields: e.changedFields,
		createdAt: moment(e.createdAt).format(momentFormat),
		method: e.method,
		user: e.user
	}));
};

const mapEscalations = (entities, locale, isCsv) => {
	const fields = entities.map(e => ({
		...(isCsv && { _id: e._id }),
		...(isCsv && { impact: e.impact[locale] }),
		...(isCsv && e.lastApproved && {
			lastUpdater: e.lastApproved?.otherUpdate?.userEmail,
			lastUpdatedAt: e.lastApproved?.otherUpdate?.date,
			draftApprover: e.lastApproved?.draft?.userEmail,
			draftApprovedAt: e.lastApproved?.draft?.date,
			reviewApprover: e.lastApproved?.review?.userEmail,
			reviewApprovedAt: e.lastApproved?.review?.date,
		}),
		divisionsIds: e.divisions.map(div => div.name),
		countryId: e.country.nameEn,
		categoryId: e.category.nameEn,
		solution: e.solutionObject.name,
		...(isCsv && { solutionDisplayId: e.solutionObject.displayId }),
		description: e.description[locale],
		primaryContactId: e.primaryContact.it4youTeam,
		...(isCsv && { furtherContacts: e.secondaryContacts.map(c => c.it4youTeam) }),
		businessImpactManagersIds: e.businessImpactManagers.map(bim => bim?.description),
	}));

	return fields;
};

const mapEntities = (resource) => ({
	'countries': mapCountries,
	'divisions': mapDivisions,
	'businessImpactManagers': mapBusinessImpactManagers,
	'categories': mapCategories,
	'serviceTeams': mapServiceTeams,
	'teamCategories': mapTeamCategories,
	'users': mapUsers,
	'duties': mapDuties,
	'changeHistory': mapChangeHistory,
	'escalations': mapEscalations,
	'escalationDrafts': mapEscalations,
})[resource];

const mapColumnStyles = (resource) => ({
	'countries': {},
	'divisions': {},
	'businessImpactManagers': { 0: { cellWidth: 'auto' }, 1: { cellWidth: 40 }, 2: { cellWidth: 40 }, 3: { cellWidth: 20 }, 4: { cellWidth: 21 } },
	'categories': {},
	'serviceTeams': {},
	'teamCategories': {},
	'users': {},
	'duties': {},
	'changeHistory': { 0: { cellWidth: 20 }, 1: { cellWidth: 50 }, 2: { cellWidth: 'auto' }, 3: { cellWidth: 22 }, 4: { cellWidth: 20 }, 5: { cellWidth: 20 } },
	'escalations': { 0: { cellWidth: 25 }, 1: { cellWidth: 25 }, 2: { cellWidth: 'auto' }, 3: { cellWidth: 'auto' }, 4: { cellWidth: 'auto' }, 5: { cellWidth: 'auto' }, 6: { cellWidth: 'auto' } },
	'escalationDrafts': { 0: { cellWidth: 25 }, 1: { cellWidth: 25 }, 2: { cellWidth: 'auto' }, 3: { cellWidth: 'auto' }, 4: { cellWidth: 'auto' }, 5: { cellWidth: 'auto' }, 6: { cellWidth: 'auto' } },
})[resource]


const handleDownloadPdf = async (props, translate, locale) => {
	const response = await fetch(Config.apiUri + "/pdf-download", {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
		},
		body: JSON.stringify(props)
	}).then(async res => {
		const result = await res.json()
		const mappedEntities = mapEntities(props.resource)(result, locale, false);
		const keys = Object.keys(mappedEntities[0]);
		const translatedKeys = keys.map(a => translate(`resources.${props.resource}.fields.${a}`))

		// l is for landscape, p is for portrait
		const doc = new jsPDF('l', 'mm', 'a4');
		const head = [translatedKeys]
		const body = [];
		const columnStyles = mapColumnStyles(props.resource)

		for (const entity of mappedEntities) {
			body.push(Object.values(entity));
		}

		autoTable(doc, {
			margin: 5,
			columnStyles,
			head,
			body,
		});

		const fileName = translate(`resources.${props.resource}.name`);
		doc.save(fileName);
	});
}



const handleDownloadCSV = async (props, translate, locale) => {
	const response = await fetch(Config.apiUri + "/pdf-download", {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			authorization: "Bearer " + localStorage.getItem("oicdAuthTokenEska"),
		},
		body: JSON.stringify(props)
	}).then(async res => {
		const result = await res.json()
		const mappedEntities = mapEntities(props.resource)(result, locale, true);

		const replacedItems = mappedEntities.map(entity => {
			return Object.keys(entity).map(key => {
				const newKey = translate(`resources.${props.resource}.fields.${key}`);
				return { [newKey]: entity[key] };
			})
		})

		jsonExport(replacedItems, {
			rowDelimiter: ';'
		}, (err, csv) => {
			downloadCSV(csv, translate(`resources.${props.resource}.name`));
		});
	});
}

const DownloadPDFButton = (props) => {
	const translate = useTranslate();
	const locale = useLocale();

	return (
		<Button
			className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-5g6lod-MuiButtonBase-root-MuiButton-root-RaButton-root'
			label='Export'
			onClick={() => handleDownloadPdf(props, translate, locale)}
			disabled={props.disabled}
		>
			<div >
				{<DownloadIcon fontSize='small' />} Export PDF
			</div>
		</Button>
	)
};

const DownloadCSVButton = (props) => {
	const translate = useTranslate();
	const locale = useLocale();

	return (
		<Button
			className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-5g6lod-MuiButtonBase-root-MuiButton-root-RaButton-root'
			label='Export'
			onClick={() => handleDownloadCSV(props, translate, locale)}
			disabled={props.disabled}
		>
			<div >
				{<DownloadIcon fontSize='small' />} Export CSV
			</div>
		</Button>
	)
};

const withoutCreateButton = ['changeHistory', 'escalationDrafts', 'declines', 'hiddenEscalation']

export const ListActions = ({ filters }) => {
	const {
		total,
		isLoading,
		sort,
		resource,
		filterValues,
		showFilter,
		displayedFilters,
		hasCreate
	} = useListContext();

	return <TopToolbar>
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		{!withoutCreateButton.includes(resource) && <CreateButton />}
		<DownloadCSVButton disabled={isLoading || total === 0} {...{ resource, filterValues, currentSort: sort, total }} />
		<DownloadPDFButton disabled={isLoading || total === 0} {...{ resource, filterValues, currentSort: sort, total }} />
	</TopToolbar>
};
ListActions.defaultProps = {
	selectedIds: [],
	onUnselectItems: () => null,
};
