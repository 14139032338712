import React from 'react';
import {
	List, Datagrid, Filter,
	TextField, DateField,
	usePermissions
} from 'react-admin';
import { QueryableTextInput } from '../common';
import { ListActions } from './utils/pdfDownload';


const ChangeHistoryFilter = props => (
	<Filter {...props}>
		<QueryableTextInput source="entityType" />
		<QueryableTextInput source="docId" />
		<QueryableTextInput source="changedFields" />
		<QueryableTextInput source="method" />
		<QueryableTextInput source="user" />
	</Filter>
);

export const ChangeHistoryList = props => {
	const { permissions: ccAdmin } = usePermissions();
	if (ccAdmin !== "true") return;

	return (
		<List filterDefaultValues={{ docId: props.id }}
			filters={<ChangeHistoryFilter />}
			sort={{ field: '_id', order: 'DESC' }}
			actions={<ListActions {...props} />}
			{...props}
			title="Change History">
			<Datagrid>
				<TextField source="entityType" />
				<TextField source="docId" />
				<TextField source="changedFields" />
				<DateField source="createdAt" showTime />
				<TextField source="method" />
				<TextField source="user" />
			</Datagrid>
		</List>
	)
};
