import React, { Component } from 'react';
import LoginBeautyForm from './loginBeautyForm';

class MyLoginPage extends Component {
    render() {
        return (
            // <MuiThemeProvider theme={this.props.theme}>
                <LoginBeautyForm/>
            // </MuiThemeProvider>
        );
    }
}

export default MyLoginPage;
